export class CategoryModel {
    uuid_category: string | undefined;
    id_category: number | undefined;
    category_name: string | undefined;
    userFields: { [fieldName: string]: any } | undefined; // Mappa dei campi utente

    constructor(uuid_category:string, id_category: number, category_name: string) {
        this.uuid_category = uuid_category;
        this.id_category = id_category;
        this.category_name = category_name;
        this.userFields = {};
    }

}
