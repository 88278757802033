<div class="container" id="section" *ngIf="!spinnerView">
    <div class="breadcrumb">
      <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
      <span class="breadcrumb-previous" (click)="goTo('landing')">Gestione Prenotazioni</span> >
      <span class="breadcrumb-current">Estrazione Prenotazioni</span>
    </div>

    <div class="my-row">
    
      <mat-form-field class="my-form-field-color my-row-element-3" appearance="fill">
        <mat-label>Seleziona l'evento di interesse</mat-label>
        <mat-select [(ngModel)]="eventSelected" name="typeSelect">
          <mat-option *ngFor="let item of allEvents" [value]="item">{{item.event_name}}</mat-option>
        </mat-select>
      </mat-form-field>
    
      <div class="my-row-element-2">
        <button class="my-button confirm" mat-raised-button [disabled]="isSearchDisabled()"
          (click)="search()">Cerca</button>
      </div>
    </div>

    <div class="mat-elevation-z8 my-following-row" *ngIf="viewReservations">
      <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="data_evento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Data/Ora Prenotazione
            </th>
            <td mat-cell *matCellDef="let element"> {{element.data_ora_evento}} </td>
          </ng-container>

          <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Cliente
            </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente}} </td>
          </ng-container>

          <ng-container matColumnDef="cliente_email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Email Cliente
            </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente_email}} </td>
          </ng-container>

          <ng-container matColumnDef="cliente_cellulare">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Telefono Cliente
            </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente_cellulare}} </td>
          </ng-container>

          <ng-container matColumnDef="numero_partecipanti">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Partecipanti
            </th>
            <td mat-cell *matCellDef="let element"> {{element.numero_partecipanti}} </td>
          </ng-container>
    
          <ng-container matColumnDef="identificativo_prenotazione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Identificativo Prenotazione
            </th>
            <td mat-cell *matCellDef="let element"> {{element.identificativo_prenotazione}} </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">Nessun dato disponibile per l'evento selezionato.</td>
          </tr>
      </table>
  
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" 
                      showFirstLastButtons>
      </mat-paginator>
    </div>

    <div class="my-button-container">
      <button *ngIf="btnDownload" class="my-button confirm"
        mat-raised-button
        (click)="downloadFile()"
        >
        Scarica Report
        <img src="./../../assets/file_download_white_24dp.svg" style="margin-left: 20px;">
      </button>
    </div>
</div>

<div class="spinner-container" *ngIf="spinnerView">
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>