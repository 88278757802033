export class CredemEvent {
  uuid_event: string | undefined;
  event_name: string | undefined;
  event_address: string | undefined;
  event_maps_link: string | undefined;
  event_description: string | undefined;
  event_date: Date | string | undefined;
  reservation_end_date: Date | string | undefined;
  event_slots: Array<EventSlot> | undefined;
  event_max_reservations_per_user: number | undefined;
  event_is_private: boolean = false;
  event_fk_category: number | undefined;
  event_category_name: string | undefined;
  uuid_event_category: string | undefined;

  constructor() {
    this.event_slots = new Array<EventSlot>();
  }
}

export class EventSlot {
  // Es. '08:00'
  start_time: string | null;
  end_time: string | null;
  max_users: number | null;
  id_event_slot?: number;

  constructor(start?: string, end?: string, max?: number, slot_id?: number) {
    start ? this.start_time = start : this.start_time = null;
    end ? this.end_time = end : this.end_time = null;
    max ? this.max_users = max : this.max_users = null;
    this.id_event_slot = slot_id;
  }

  isValid() {
    if (
      !this.end_time ||
      this.end_time[2] != ':' ||
      !this.start_time ||
      this.start_time[2] != ':' ||
      !this.max_users ||
      this.max_users <= 0
    ) return false;

    let start: number[] = this.start_time.split(':').map(el => parseInt(el));
    let end: number[] = this.end_time.split(':').map(el => parseInt(el));

    if (
      start[0] > end[0] ||
      (start[0] == end[0] && start[1] >= end[1])
    ) return false;

    return true;
  }
}
