import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TIMESLOTS } from 'src/app/constants/times';
import { EventSlot } from 'src/app/model/credemEvent.model';

@Component({
  selector: 'app-event-slots-modal',
  templateUrl: './event-slots-modal.component.html',
  styleUrls: ['./event-slots-modal.component.scss']
})
export class EventSlotsModalComponent implements OnInit {

  slotsOrari: Array<EventSlot> = [];
  minCapacity: Array<number> = [];
  errors: string[] = [];

  orari = TIMESLOTS;

  constructor(
    public dialogRef: MatDialogRef<EventSlotsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {inputSlots: Array<EventSlot>},
  ) { }

  ngOnInit(): void {
    this.data.inputSlots.forEach(el => {
      this.slotsOrari.push(el);
      this.minCapacity.push(el.id_event_slot ? el.max_users! : 1);
    });

    this.checkEmptySLots();
  }

  private checkEmptySLots() {
    if (this.slotsOrari.length === 0) {
      this.addSlot();
    }
  }

  removeSlot(index: number) {
    if (index > 0) {
      this.slotsOrari.splice(index, 1);
    }
  }

  addSlot() {
    this.slotsOrari.push(new EventSlot());
  }

  allSlotsValid() {
    let valid: boolean = true;

    if (this.slotsOrari.length == 0) return false;

    this.slotsOrari.forEach(el => {
      if (!el.isValid()) {
        valid = false;
      }
    });

    if (!valid) {
      this.errors = ["Uno degli slot inseriti è invalido o incompleto."];
    } else {
      this.errors = [];
    }

    return valid;
  }

  save() {
    this.dialogRef.close(this.slotsOrari);
  }

  close() {
    this.dialogRef.close();
  }
}
