import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CredemEvent } from '../model/credemEvent.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private api_prefix: string;

  constructor(private http: HttpClient) {
    this.api_prefix = environment.backEndUrl + "/events";
  }

  createSingleEvent(event: CredemEvent): Observable<any> {
    let url = this.api_prefix;
    return this.http.post(url, event);
  }

  updateSingleEvent(event: CredemEvent, uuid: string) {
    let url = this.api_prefix + `/${uuid}`;
    return this.http.put(url, event);
  }

  deleteSingleEvent(uuid: string) {
    let url = this.api_prefix + `/${uuid}`;
    return this.http.delete(url);
  }

  getAllEvents(active_only: boolean = false): Observable<any> {
    let url = this.api_prefix;
    
    let params: HttpParams = new HttpParams();
    params = params = params.set("active_only", active_only);

    return this.http.get(url, { params: params });
  }

  getEvent(uuid: string): Observable<any> {
    let url = this.api_prefix + `/${uuid}`;
    return this.http.get(url);
  }
}
