import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    private api_prefix: string;

    constructor(private http: HttpClient) {
        this.api_prefix = environment.backEndUrl + "/categories";
    }

    getCategory(category_id: number): Observable<any> {
        let url = this.api_prefix + `/${category_id}`;
        return this.http.get(url);
    }

    getCategories(): Observable<any> {
        let url = this.api_prefix;
        return this.http.get(url);
    }

}
