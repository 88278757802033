<div class="spinner-container" *ngIf="spinnerView">
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div class="container" *ngIf="!spinnerView">
  <div class="breadcrumb">
    <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
    <span class="breadcrumb-current">Gestione Eventi</span>
  </div>

  <div class="menu-buttons">

    <div class="menu-elements" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
      [matRippleUnbounded]="false" (click)="goTo('new')">
      <div>
        <mat-icon svgIcon="new" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
      </div>
      <div style="margin-top: 15px">Crea Nuovo Evento</div>
    </div>

    <div class="menu-elements" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
      [matRippleUnbounded]="false" (click)="goTo('edit')">
      <div>
        <mat-icon svgIcon="build" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
      </div>
      <div style="margin-top: 15px">Modifica Evento</div>
    </div>

  </div>
</div>
