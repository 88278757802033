import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROLES } from 'src/app/model/user.model';
import { ROUTES } from 'src/app/route/routes';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reservation-landing',
  templateUrl: './reservation-landing.component.html',
  styleUrls: ['./reservation-landing.component.scss']
})
export class ReservationLandingComponent implements OnInit {

  private roles!: ROLES[];
  handleReservation!: boolean;
  viewReservation!: boolean;
  
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.roles = storageRoles;
      this.getRole();
    }
  }

  private getRole() {
    if (this.roles.includes(ROLES.ADMIN)) {
      this.handleReservation = true;
      this.viewReservation = true;
    } else {
      if (this.roles.includes(ROLES.REPORT_READONLY)) {
        this.viewReservation = true;
      }
      if (this.roles.includes(ROLES.BANCA)){
        this.handleReservation = true;
        this.viewReservation = true;
      }
    }
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'report':
        this.router.navigate([ROUTES.RESERVATION_REPORT.path]);
        break;
      case 'handle':
        this.router.navigate([ROUTES.RESERVATION_HANDLE.path]);
        break;
    }
  }

}
