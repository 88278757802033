<div class="modal-container">
  <div mat-dialog-title class="title">Impostazioni Slot</div>
  <mat-dialog-content class="mat-typography">
    <div class="inner-section">

      <ng-container *ngFor="let item of slotsOrari; let i = index">
        <div class="flex-time">
          <div class="time-table-flex">
            <label>Dalle:</label>
            <select [(ngModel)]="item.start_time" [disabled]="item.id_event_slot ? true : false">
              <option *ngFor="let ora of orari" [value]="ora">{{ ora }}</option>
            </select>
          </div>
          <div class="time-table-flex">
            <label>Alle:</label>
            <select [(ngModel)]="item.end_time" [disabled]="item.id_event_slot ? true : false">
              <option *ngFor="let ora of orari" [value]="ora">{{ ora }}</option>
            </select>
          </div>
          <div class="time-table-flex">
            <label>Capacità:</label>
            <input name="eventCapacity" id="eventCapacity" type="number"
              [min]="minCapacity[i]" max="100" [(ngModel)]="item.max_users" autocomplete="off" required />
          </div>
          <button *ngIf="i != 0"
            class="my-button confirm small"
            mat-raised-button
            (click)="removeSlot(i)"
            [disabled]="item.id_event_slot ? true : false"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </ng-container>

      <div style="padding: 20px">
        <button
          class="my-button confirm small"
          mat-raised-button
          (click)="addSlot()"
          [disabled] = "!allSlotsValid()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>

    </div>
  </mat-dialog-content>
  <ng-container *ngIf="!(slotsOrari.length == 1 && slotsOrari[0].start_time == null)">
    <ng-container *ngFor="let error of errors">
      <p class="error" id="errorName">Errore:</p>
      <br />
      <span style="font-size: 16px; color: #20623b; display: flex">
        {{ error }}
      </span>
    </ng-container>
  </ng-container>

  <mat-dialog-actions align="end" style="margin-top: 10px">
    <button
      class="my-button back"
      mat-raised-button
      (click)="close()"
    >
      ANNULLA
    </button>
    <button
      class="my-button confirm"
      mat-raised-button
      (click)="save()"
      [disabled] = "!allSlotsValid()"
    >
      SALVA
    </button>
  </mat-dialog-actions>
</div>
