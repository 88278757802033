import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ROLES } from '../../model/user.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  private roles!: ROLES[];

  viewEventi!: boolean;
  viewReservation!: boolean;
  viewSettings!: boolean;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    let storageRoles = this.userService.getCurrentUserRoles();
    if(storageRoles) {
      this.roles = storageRoles;
      this.getRole();
    }
  }

  private getRole() {
    if (this.roles.includes(ROLES.ADMIN)) {
      this.viewEventi = true;
      this.viewReservation = true;
      this.viewSettings = !environment.isProd;
    } else {
      if (this.roles.includes(ROLES.BANCA)) {
        this.setStyle('space-around');
        this.viewEventi = true;
        this.viewReservation = true;
      } 
      
      if (this.roles.includes(ROLES.REPORT_READONLY)){
        this.setStyle('space-around');
        this.viewEventi = true;
        this.viewReservation = true;
      }
    }
  }

  private setStyle(style: string) {
    const section = document.getElementById('section');

    if (section != null) {
      section.style.justifyContent = style;
    }
  }
}
