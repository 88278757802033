<div class="section" id="section">

    <div class="big-button" *ngIf="viewEventi" (click)="goToUrl(event_url)">
        <div class="img-button">
            <mat-icon svgIcon="event-home" aria-hidden="false" style="height: 250px; width: 250px;"></mat-icon>
        </div>
        <div class="label-box">
            <h3>Gestione Eventi</h3>
        </div>
    </div>

    <div class="big-button" *ngIf="viewReservation" (click)="goToUrl(reservation_url)">
        <div class="img-button">
            <mat-icon svgIcon="reservation-home" aria-hidden="false" style="height: 250px; width: 250px;"></mat-icon>
        </div>
        <div class="label-box">
            <h3>Gestione Prenotazioni</h3>
        </div>
    </div>

    <div class="big-button" *ngIf="viewSettings" (click)="goToUrl(settings_url)">
        <div class="img-button">
            <mat-icon svgIcon="settings-home" aria-hidden="false" style="height: 250px; width: 250px;"></mat-icon>
        </div>
        <div class="label-box">
            <h3>Impostazioni Generali</h3>
        </div>
    </div>

</div>