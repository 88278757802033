import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './landing/main-page/main-page.component';
import { NotAllowedComponent } from './landing/not-allowed/not-allowed.component';
import { PreLandingComponent } from './landing/pre-landing/pre-landing.component';
import { ROUTES } from './route/routes';
import { AuthGuard } from './auth/auth.guard';
import { EventLandingComponent } from './component/event/event-landing/event-landing.component';
import { EventNewComponent } from './component/event/event-new/event-new.component';
import { ReservationLandingComponent } from './component/reservation/reservation-landing/reservation-landing.component';
import { ReservationReportComponent } from './component/reservation/reservation-report/reservation-report.component';
import { ReservationHandlingComponent } from './component/reservation/reservation-handling/reservation-handling.component';
import { SettingsLandingComponent } from './component/settings/settings-landing/settings-landing.component';
import { EventEditComponent } from './component/event/event-edit/event-edit.component';

const routes: Routes = [
  {
    path: ROUTES.PRE_LANDING.path,
    component: PreLandingComponent,
    canActivate: []
  },
  {
    path: ROUTES.HOME.path,
    component: MainPageComponent,
    canActivate: [AuthGuard],
  },
  // EVENT
  {
    path: ROUTES.EVENT.path,
    component: EventLandingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.EVENT_NEW.path,
    component: EventNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.EVENT_EDIT.path,
    component: EventEditComponent,
    canActivate: [AuthGuard],
  },
  // RESERVATION
  {
    path: ROUTES.RESERVATION.path,
    component: ReservationLandingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.RESERVATION_REPORT.path,
    component: ReservationReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.RESERVATION_HANDLE.path,
    component: ReservationHandlingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ROUTES.SETTINGS.path,
    component: SettingsLandingComponent,
    canActivate: [AuthGuard],
  },
  // OTHER
  {
    path: ROUTES.NOT_ALLOWED.path,
    component: NotAllowedComponent,
    canActivate: [],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
