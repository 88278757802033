import { ChangeDetectorRef, Component } from '@angular/core';
import { User } from './model/user.model';
import { UserService } from './services/user.service';
import { takeWhile } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  BUILD,
  CONSULTANT,
  EVENT,
  EVENT_LANDING,
  MAIL,
  NEW,
  PENDING_ACTIONS,
  REPORT,
  REPORT_VIC,
  RESERVATION_LANDING,
  RESERVATION_REPORT,
  SERVICE,
  WATCH_LATER,
} from './constants/icon';
import { Router } from '@angular/router';
import { ROUTES } from './route/routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  user!: User;
  loading: boolean = true;

  allowPopupMessage: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.addSVGIcons();
  }

  ngOnInit(): void {
    this.router.navigateByUrl(ROUTES.PRE_LANDING.absolute_path);
    this.checkAuth();
  }

  private checkAuth() {
    this.userService
      .getCurrentUser()
      .pipe(
        takeWhile((value) => {
          return !(value instanceof User);
        }, true)
      )
      .subscribe(
        (user) => {
          if (user) {
            if (typeof user === 'string') {
              if (user === 'doLogin') {
                this.userService.login();
              }

              if (user === 'doPopUp') {
                this.allowPopupMessage = true;
              } else {
                this.allowPopupMessage = false;
              }
              this.changeDetectorRef.detectChanges();
            } else {
              this.user = user;
              this.loading = false;
              this.changeDetectorRef.detectChanges();
            }
          }
        },
        (err) => {
          console.error(err);
        }
      );
  }

  private addSVGIcons() {
    // LANDING
    //this.iconRegistry.addSvgIconLiteral('agency-home', this.sanitizer.bypassSecurityTrustHtml(AGENCY_LANDING));
    //this.iconRegistry.addSvgIconLiteral('bank-home', this.sanitizer.bypassSecurityTrustHtml(BANK_LANDING));
    //this.iconRegistry.addSvgIconLiteral('consultant-home', this.sanitizer.bypassSecurityTrustHtml(CONSULTANT_LANDING));
    //this.iconRegistry.addSvgIconLiteral('report-home', this.sanitizer.bypassSecurityTrustHtml(REPORT_LANDING));
    this.iconRegistry.addSvgIconLiteral(
      'event-home',
      this.sanitizer.bypassSecurityTrustHtml(EVENT_LANDING)
    );
    this.iconRegistry.addSvgIconLiteral(
      'reservation-home',
      this.sanitizer.bypassSecurityTrustHtml(RESERVATION_LANDING)
    );
    this.iconRegistry.addSvgIconLiteral(
      'settings-home',
      this.sanitizer.bypassSecurityTrustHtml(SERVICE)
    );

    // AGENCY
    this.iconRegistry.addSvgIconLiteral(
      'new',
      this.sanitizer.bypassSecurityTrustHtml(NEW)
    );
    this.iconRegistry.addSvgIconLiteral(
      'build',
      this.sanitizer.bypassSecurityTrustHtml(BUILD)
    );

    // BANK
    this.iconRegistry.addSvgIconLiteral(
      'watch-later',
      this.sanitizer.bypassSecurityTrustHtml(WATCH_LATER)
    );
    this.iconRegistry.addSvgIconLiteral(
      'pending-actions',
      this.sanitizer.bypassSecurityTrustHtml(PENDING_ACTIONS)
    );
    this.iconRegistry.addSvgIconLiteral(
      'event',
      this.sanitizer.bypassSecurityTrustHtml(EVENT)
    );
    this.iconRegistry.addSvgIconLiteral(
      'mail',
      this.sanitizer.bypassSecurityTrustHtml(MAIL)
    );

    // CONSULTANT
    this.iconRegistry.addSvgIconLiteral(
      'associate-consultant',
      this.sanitizer.bypassSecurityTrustHtml(CONSULTANT)
    );

    // REPORT
    this.iconRegistry.addSvgIconLiteral(
      'report',
      this.sanitizer.bypassSecurityTrustHtml(REPORT)
    );
    this.iconRegistry.addSvgIconLiteral(
      'reportVIC',
      this.sanitizer.bypassSecurityTrustHtml(REPORT_VIC)
    );

    // RESERVATION
    this.iconRegistry.addSvgIconLiteral(
      'reservation-report',
      this.sanitizer.bypassSecurityTrustHtml(RESERVATION_REPORT)
    );

    // SETTINGS
    this.iconRegistry.addSvgIconLiteral(
      'service',
      this.sanitizer.bypassSecurityTrustHtml(SERVICE)
    );

    // OTHER
    //
  }

  logout() {
    this.userService.logout();
  }
}
