export class ReportEvent {
    identificativo_prenotazione: string | null;
    nome_evento: string;
    data_ora_evento: string;
    data_creazione: string;
    cliente: string;
    cliente_email: string;
    cliente_cellulare: string;
    codice_fiscale: string;
    numero_partecipanti: number;
    numero_accompagnatori: number;

    constructor(
        identificativo_prenotazione: string | null,
        nome_evento: string,
        data_ora: string, 
        data_creazione:string,
        cliente: string,
        email_cliente: string,
        cellulare_cliente: string,
        codice_fiscale: string,
        partecipanti: number,
        accompagnatori: number,
        ) {
        this.identificativo_prenotazione = identificativo_prenotazione,
        this.nome_evento = nome_evento;
        this.data_ora_evento = data_ora;
        this.data_creazione = data_creazione;
        this.cliente = cliente;
        this.cliente_email = email_cliente;
        this.cliente_cellulare = cellulare_cliente;
        this.codice_fiscale = codice_fiscale;
        this.numero_partecipanti = partecipanti;
        this.numero_accompagnatori = accompagnatori;
    }
}