// LANDING

export const AGENCY_LANDING = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#20623B">
<path d="M9.8,16.7v-2.9h31.3v2.9H9.8z M10.1,45V32.4H7.7v-2.9l2.1-9.9h31.3l2.1,9.9v2.9h-2.4V45h-2.9V32.4h-9.2V45H10.1
	z M13,42.1h12.8v-9.7H13V42.1z M10.6,29.5h29.8H10.6z M10.6,29.5h29.8l-1.5-6.9H12.1L10.6,29.5z"/>
<path d="M7.3,17.9L7,16c-0.2-0.1-0.4-0.2-0.6-0.3S6,15.4,5.8,15.3L4,16.1l-1.4-2.5l1.7-1.2c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3L2.6,9.9L4,7.4l1.8,0.8C6,8.1,6.1,8,6.4,7.9S6.8,7.6,7,7.6l0.3-1.9h2.8
	l0.3,1.9c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4l1.8-0.8l1.4,2.5l-1.7,1.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
	c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3l1.7,1.2l-1.4,2.5l-1.8-0.8c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.3l-0.3,1.9H7.3z
	 M8.7,13.8c0.6,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4S9.2,9.8,8.7,9.8s-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4
	s0.2,1,0.6,1.4C7.7,13.6,8.1,13.8,8.7,13.8z M8.7,12.8c-0.3,0-0.5-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8
	c0.2-0.2,0.5-0.3,0.8-0.3s0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.1,0.5-0.3,0.8C9.2,12.7,9,12.8,8.7,12.8z M8.7,11.8L8.7,11.8
	L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8L8.7,11.8
	L8.7,11.8z M8,17h1.3l0.2-1.7c0.3-0.1,0.7-0.2,1-0.4c0.3-0.2,0.6-0.4,0.8-0.6L13,15l0.6-1.1l-1.4-1.1c0-0.2,0.1-0.3,0.1-0.5
	c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5c0-0.2-0.1-0.3-0.1-0.5l1.4-1.1L13,8.6l-1.6,0.7c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.3-1-0.3
	L9.4,6.6H8L7.8,8.3c-0.3,0.1-0.7,0.2-1,0.4C6.5,8.8,6.3,9,6,9.3L4.4,8.6L3.8,9.7l1.4,1.1c0,0.2-0.1,0.3-0.1,0.5s0,0.3,0,0.5
	s0,0.3,0,0.5c0,0.2,0.1,0.3,0.1,0.5l-1.4,1.1L4.4,15L6,14.3c0.2,0.2,0.5,0.5,0.8,0.6c0.3,0.2,0.6,0.3,1,0.4L8,17z"/>
<path d="M16.4,9l-0.2-1.2c-0.1,0-0.3-0.1-0.4-0.2s-0.3-0.2-0.4-0.2l-1.1,0.5l-0.9-1.6l1.1-0.8c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2L13.4,4l0.9-1.6l1.1,0.5c0.1-0.1,0.2-0.2,0.4-0.2s0.3-0.1,0.4-0.2l0.2-1.2
	h1.8l0.2,1.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.4,0.2l1.1-0.5L21.2,4l-1.1,0.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l1.1,0.8l-0.9,1.6l-1.1-0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.2L18.2,9H16.4z
	 M17.3,6.4c0.4,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9c0-0.4-0.1-0.6-0.4-0.9C18,4,17.7,3.9,17.3,3.9S16.7,4,16.4,4.3
	s-0.4,0.5-0.4,0.9c0,0.4,0.1,0.6,0.4,0.9C16.7,6.3,17,6.4,17.3,6.4z M17.3,5.8c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
	c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2s0.3,0.1,0.5,0.2C17.9,4.8,18,5,18,5.1c0,0.2-0.1,0.3-0.2,0.5
	C17.7,5.8,17.5,5.8,17.3,5.8z M17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1
	L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1L17.3,5.1z M16.9,8.5h0.9l0.1-1.1c0.2-0.1,0.4-0.1,0.6-0.2
	C18.7,7,18.9,6.9,19,6.7l1,0.4l0.4-0.7l-0.9-0.7c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3
	l0.9-0.7L20,3.1l-1,0.4c-0.1-0.2-0.3-0.3-0.5-0.4C18.3,3,18.1,3,17.9,2.9l-0.1-1.1h-0.9l-0.1,1.1c-0.2,0-0.4,0.1-0.6,0.2
	c-0.2,0.1-0.4,0.2-0.5,0.4l-1-0.4l-0.4,0.7l0.9,0.7c0,0.1,0,0.2-0.1,0.3s0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3
	l-0.9,0.7l0.4,0.7l1-0.4C15.8,6.9,16,7,16.1,7.1c0.2,0.1,0.4,0.2,0.6,0.2L16.9,8.5z"/>
</svg>
`;

export const BANK_LANDING = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#20623B">
<path d="M9.6,44V13.1h16.8v7.1H44V44H9.6z M12.2,41.4h4.5v-4.5h-4.5V41.4z M12.2,34.3h4.5v-4.5h-4.5V34.3z M12.2,27.2
	h4.5v-4.5h-4.5V27.2z M12.2,20.2h4.5v-4.5h-4.5V20.2z M19.3,41.4h4.5v-4.5h-4.5V41.4z M19.3,34.3h4.5v-4.5h-4.5V34.3z M19.3,27.2
	h4.5v-4.5h-4.5V27.2z M19.3,20.2h4.5v-4.5h-4.5V20.2z M26.4,41.4h15V22.7h-15v4.5h3.4v2.6h-3.4v4.5h3.4v2.6h-3.4V41.4z M34.3,29.8
	v-2.6h2.6v2.6H34.3z M34.3,36.9v-2.6h2.6v2.6H34.3z"/>
<path d="M8.5,17.4l-0.3-1.9c-0.2-0.1-0.4-0.2-0.6-0.3C7.3,15,7.2,14.9,7,14.8l-1.8,0.8l-1.4-2.5l1.7-1.2
	c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3L3.8,9.4l1.4-2.5L7,7.7c0.2-0.1,0.4-0.3,0.6-0.4C7.8,7.2,8,7.1,8.2,7
	l0.3-1.9h2.8L11.6,7c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4l1.8-0.8L16,9.4l-1.7,1.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
	c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3l1.7,1.2l-1.4,2.5l-1.8-0.8c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.3l-0.3,1.9H8.5z
	 M9.9,13.2c0.6,0,1-0.2,1.4-0.6s0.6-0.9,0.6-1.4c0-0.6-0.2-1-0.6-1.4s-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4
	c0,0.6,0.2,1,0.6,1.4C8.9,13,9.3,13.2,9.9,13.2z M9.9,12.3c-0.3,0-0.5-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8
	c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3s0.3,0.5,0.3,0.8c0,0.3-0.1,0.5-0.3,0.8C10.4,12.2,10.2,12.3,9.9,12.3z M9.9,11.2
	L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2L9.9,11.2
	L9.9,11.2L9.9,11.2z M9.2,16.4h1.3l0.2-1.7c0.3-0.1,0.7-0.2,1-0.4c0.3-0.2,0.6-0.4,0.8-0.6l1.6,0.7l0.6-1.1l-1.4-1.1
	c0-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5c0-0.2-0.1-0.3-0.1-0.5l1.4-1.1L14.2,8l-1.6,0.7
	c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.3-1-0.3L10.6,6H9.2L9,7.7C8.7,7.8,8.3,7.9,8,8.1C7.7,8.3,7.5,8.5,7.2,8.7L5.6,8L5,9.1
	l1.4,1.1c0,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.3,0.1,0.5L5,13.3l0.6,1.1l1.6-0.7
	C7.5,14,7.7,14.2,8,14.3c0.3,0.2,0.6,0.3,1,0.4L9.2,16.4z"/>
<path d="M17.6,8.5l-0.2-1.2c-0.1,0-0.3-0.1-0.4-0.2s-0.3-0.2-0.4-0.2l-1.1,0.5l-0.9-1.6L15.7,5c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-1.1-0.8l0.9-1.6l1.1,0.5c0.1-0.1,0.2-0.2,0.4-0.2s0.3-0.1,0.4-0.2l0.2-1.2h1.8
	l0.2,1.2C19.7,2,19.9,2,20,2.1c0.1,0.1,0.3,0.2,0.4,0.2l1.1-0.5l0.9,1.6l-1.1,0.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2
	c0,0.1,0,0.1,0,0.2l1.1,0.8l-0.9,1.6l-1.1-0.5C20.3,6.9,20.1,7,20,7.1c-0.1,0.1-0.3,0.1-0.4,0.2l-0.2,1.2H17.6z M18.5,5.9
	c0.4,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.5,0.4-0.9S19.7,4,19.4,3.7c-0.2-0.2-0.5-0.4-0.9-0.4s-0.6,0.1-0.9,0.4
	c-0.2,0.2-0.4,0.5-0.4,0.9s0.1,0.6,0.4,0.9C17.9,5.7,18.2,5.9,18.5,5.9z M18.5,5.3c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5
	s0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2S18.9,4,19,4.1c0.1,0.1,0.2,0.3,0.2,0.5S19.1,5,19,5.1C18.9,5.2,18.7,5.3,18.5,5.3z
	 M18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6L18.5,4.6
	L18.5,4.6L18.5,4.6L18.5,4.6z M18.1,7.9h0.9l0.1-1.1c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.4l1,0.4l0.4-0.7l-0.9-0.7
	c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3l0.9-0.7l-0.4-0.7l-1,0.4c-0.1-0.2-0.3-0.3-0.5-0.4
	s-0.4-0.2-0.6-0.2l-0.1-1.1h-0.9L18,2.4c-0.2,0-0.4,0.1-0.6,0.2C17.1,2.7,17,2.9,16.8,3l-1-0.4l-0.4,0.7L16.3,4c0,0.1,0,0.2-0.1,0.3
	c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3l-0.9,0.7l0.4,0.7l1-0.4c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2
	L18.1,7.9z"/>
</svg>
`;

export const CONSULTANT_LANDING = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#20623B">
<path d="M22.9,9.7c-2.9,0.7-5.3,3.1-6.2,6.2c-0.3,1.2-0.4,3.4-0.1,4.5c0.5,1.8,1.4,3.3,2.7,4.6c1.5,1.4,3.1,2.1,5.1,2.2
	c3.5,0.2,6.8-2,8.2-5.4c1-2.5,0.8-5.7-0.6-8c-0.7-1.1-2.2-2.7-3.3-3.3C27,9.5,24.7,9.2,22.9,9.7z"/>
<path d="M22.3,29.2c0,0,0.3,0.7,0.6,1.5l0.6,1.5l-0.3,3.2c-0.1,1.7-0.3,3.2-0.3,3.2c0,0-1.2-2-2.7-4.5
	c-1.5-2.5-2.7-4.6-2.8-4.7c-0.1-0.2-0.3-0.1-1.3,0.3c-4.4,1.9-7.2,4.8-8.1,8.2c-0.6,2.3-0.2,3,2.9,4.5c2.1,1,4.9,1.8,8.1,2.3
	c2.2,0.3,9.1,0.3,11.2,0c3.4-0.5,5.9-1.2,8.2-2.3c2.5-1.2,3-1.7,3-3c0-1.3-0.5-2.8-1.6-4.4c-1.3-1.9-3.4-3.6-6.3-5
	c-1.3-0.6-1.6-0.7-1.7-0.6c-0.1,0.1-1.3,2.2-2.8,4.7c-1.5,2.5-2.7,4.5-2.7,4.5c0,0-0.2-1.5-0.3-3.2L26,32.3l0.6-1.5
	c0.3-0.8,0.6-1.5,0.6-1.6s-1.1-0.1-2.5-0.1C23.4,29.2,22.3,29.2,22.3,29.2z M35.2,39.8v0.7h-2.5h-2.5v-0.7v-0.7h2.5h2.5V39.8z"/>
<path d="M10.2,19l-0.3-1.9c-0.2-0.1-0.4-0.2-0.6-0.3s-0.4-0.3-0.6-0.4l-1.8,0.8l-1.4-2.5l1.7-1.2c0-0.1,0-0.2,0-0.3
	s0-0.2,0-0.3s0-0.2,0-0.3s0-0.2,0-0.3L5.4,11l1.4-2.5l1.8,0.8C8.8,9.2,9,9.1,9.2,9c0.3-0.1,0.5-0.2,0.7-0.3l0.3-1.9H13l0.3,1.9
	c0.2,0.1,0.4,0.1,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4l1.8-0.8l1.4,2.5L16,12.3c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3s0,0.2,0,0.3s0,0.2,0,0.3
	l1.7,1.2l-1.4,2.5l-1.8-0.8c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.3L13,19H10.2z M11.6,14.9c0.6,0,1-0.2,1.4-0.6
	c0.4-0.4,0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4s-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4
	C10.6,14.7,11,14.9,11.6,14.9z M11.6,14c-0.3,0-0.5-0.1-0.8-0.3c-0.2-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	s0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.1,0.5-0.3,0.8C12.1,13.9,11.9,14,11.6,14z M11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9
	L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9L11.6,12.9
	L11.6,12.9z M10.9,18.1h1.3l0.3-1.7c0.3-0.1,0.7-0.2,1-0.4s0.6-0.4,0.8-0.6l1.6,0.7l0.6-1.1l-1.4-1.1c0-0.2,0.1-0.3,0.1-0.5
	s0-0.3,0-0.5s0-0.3,0-0.5s-0.1-0.3-0.1-0.5l1.4-1.1l-0.6-1.1l-1.6,0.7c-0.3-0.3-0.6-0.5-0.8-0.7c-0.3-0.1-0.7-0.3-1-0.3l-0.2-1.7
	h-1.4l-0.2,1.7c-0.3,0.1-0.7,0.2-1,0.4c-0.3,0.1-0.6,0.3-0.8,0.6L7.3,9.7l-0.6,1.1l1.4,1.1c0,0.2-0.1,0.3-0.1,0.5s0,0.3,0,0.5
	s0,0.3,0,0.5s0.1,0.3,0.1,0.5L6.7,15l0.6,1.1l1.6-0.7c0.2,0.2,0.5,0.5,0.8,0.6c0.3,0.2,0.6,0.3,1,0.4L10.9,18.1z"/>
<path d="M19.3,10.2L19.1,9c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.2l-1.1,0.5l-0.9-1.6l1.1-0.8
	c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2s0-0.1,0-0.2s0-0.1,0-0.2l-1.1-0.8l0.9-1.6L18.3,4c0.1-0.1,0.2-0.2,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2
	l0.2-1.2h1.8l0.2,1.2c0.1,0,0.3,0.1,0.4,0.2C21.8,3.9,22,4,22.1,4l1.1-0.5l0.9,1.6L23,5.9C23,6,23,6,23,6.1s0,0.1,0,0.2s0,0.1,0,0.2
	s0,0.1,0,0.2l1.1,0.8l-0.9,1.6l-1.1-0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.2l-0.2,1.2C21.1,10.2,19.3,10.2,19.3,10.2
	z M20.2,7.5c0.4,0,0.6-0.1,0.9-0.4s0.4-0.5,0.4-0.8c0-0.4-0.1-0.6-0.4-0.9S20.6,5,20.2,5s-0.6,0.1-0.9,0.4c-0.2,0.2-0.4,0.5-0.4,0.9
	s0.1,0.6,0.4,0.9C19.6,7.4,19.9,7.5,20.2,7.5z M20.2,6.9c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.5
	c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5s-0.1,0.3-0.2,0.5C20.6,6.9,20.4,6.9,20.2,6.9z M20.2,6.3
	L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3L20.2,6.3
	L20.2,6.3L20.2,6.3z M19.8,9.6h0.9l0.1-1.1c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.4l1,0.4l0.4-0.7l-0.9-0.7
	c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3L23.3,5l-0.4-0.7l-1,0.4c-0.1-0.2-0.3-0.3-0.5-0.4
	C21.2,4.1,21,4.1,20.8,4l-0.1-1.1h-0.9L19.7,4c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.6,0.5l-1-0.4L17.1,5L18,5.7
	c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3l-0.9,0.7l0.4,0.7l1-0.4c0.2,0.2,0.3,0.3,0.5,0.4
	c0.2,0.1,0.4,0.2,0.6,0.2L19.8,9.6z"/>
</svg>
`;

export const REPORT_LANDING = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#20623B">
<path d="M13,42c-0.6,0-1.2-0.2-1.7-0.7c-0.5-0.5-0.7-1-0.7-1.7V18.8c0-0.6,0.2-1.2,0.7-1.7s1-0.7,1.7-0.7h11.2l2.4,2.4
	h13.5c0.6,0,1.2,0.2,1.7,0.7c0.5,0.5,0.7,1,0.7,1.7h-17l-2.4-2.4H13l0,0l0,0v20.8l0,0l0,0l4.1-16H45l-4.3,16.5
	c-0.2,0.6-0.5,1.1-0.9,1.4c-0.4,0.3-1,0.4-1.6,0.4H13z M15.6,39.6h22.8L41.8,26H18.9L15.6,39.6z M15.6,39.6L18.9,26L15.6,39.6z
	 M13,21.2v-2.4l0,0l0,0V21.2z"/>
<path d="M6.7,20.9L6.4,19c-0.2-0.1-0.4-0.2-0.6-0.3s-0.4-0.3-0.6-0.4l-1.8,0.8l-1.4-2.5l1.7-1.2c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c0-0.1,0-0.2,0-0.3l-1.7-1.2l1.4-2.5l1.8,0.8c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.3
	l0.3-1.9h2.8l0.3,1.9c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4l1.8-0.8l1.4,2.5l-1.7,1.2c0,0.1,0,0.2,0,0.3
	c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0,0.1,0,0.2,0,0.3l1.7,1.2l-1.4,2.5L11,18.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.3
	l-0.3,1.9H6.7z M8.1,16.8c0.6,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4s-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6
	c-0.4,0.4-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4C7.1,16.6,7.5,16.8,8.1,16.8z M8.1,15.9c-0.3,0-0.5-0.1-0.8-0.3C7.1,15.3,7,15.1,7,14.8
	s0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.1,0.5-0.3,0.8
	C8.6,15.8,8.4,15.9,8.1,15.9z M8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8
	L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8z M7.4,20h1.3L9,18.3c0.3-0.1,0.7-0.2,1-0.4
	c0.3-0.2,0.6-0.4,0.8-0.6l1.6,0.7l0.6-1.1l-1.4-1.1c0-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5c0-0.2-0.1-0.3-0.1-0.5
	l1.4-1.1l-0.6-1.1l-1.6,0.7c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.3-1-0.3L8.8,9.6H7.4l-0.2,1.7c-0.3,0.1-0.7,0.2-1,0.4
	c-0.3,0.2-0.6,0.4-0.8,0.6l-1.6-0.7l-0.6,1.1l1.4,1.1c0,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0,0.5s0,0.3,0,0.5c0,0.2,0.1,0.3,0.1,0.5
	l-1.4,1.1L3.8,18l1.6-0.7c0.2,0.2,0.5,0.5,0.8,0.6c0.3,0.2,0.6,0.3,1,0.4L7.4,20z"/>
<path d="M15.8,12.1l-0.2-1.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.2l-1.1,0.5l-0.9-1.6l1.1-0.8
	c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2L12.8,7l0.9-1.6l1.1,0.5c0.1-0.1,0.2-0.2,0.4-0.2
	c0.1-0.1,0.3-0.1,0.4-0.2l0.2-1.2h1.8l0.2,1.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.4,0.2l1.1-0.5L20.6,7l-1.1,0.8
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l1.1,0.8l-0.9,1.6l-1.1-0.5c-0.1,0.1-0.2,0.2-0.4,0.2
	c-0.1,0.1-0.3,0.1-0.4,0.2l-0.2,1.2H15.8z M16.7,9.4c0.4,0,0.6-0.1,0.9-0.4C17.9,8.8,18,8.5,18,8.2c0-0.4-0.1-0.6-0.4-0.9
	s-0.5-0.4-0.9-0.4c-0.4,0-0.6,0.1-0.9,0.4c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.4,0.1,0.6,0.4,0.9C16.1,9.3,16.4,9.4,16.7,9.4z M16.7,8.8
	c-0.2,0-0.3-0.1-0.5-0.2C16.1,8.5,16,8.3,16,8.2c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2
	c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.5C17.1,8.8,16.9,8.8,16.7,8.8z M16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2
	L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2z M16.3,11.5h0.9
	l0.1-1.1c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.4l1,0.4l0.4-0.7l-0.9-0.7c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3l0.9-0.7l-0.4-0.7l-1,0.4c-0.1-0.2-0.3-0.3-0.5-0.4C17.7,6,17.5,6,17.3,5.9l-0.1-1.1h-0.9
	l-0.1,1.1c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.4l-1-0.4l-0.4,0.7l0.9,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3l-0.9,0.7l0.4,0.7l1-0.4c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2L16.3,11.5z"/>
</svg>
`;

export const EVENT_LANDING = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#20623B">
<path d="M24.9,9.2c-0.6,0-1.1,0.5-1.1,1.1v2.2c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1v-2.2C26,9.7,25.6,9.2,24.9,9.2z"/>
<path d="M21.3,12.5l-2.2-2.2c-0.4-0.4-1.1-0.4-1.6,0c-0.4,0.4-0.4,1.1,0,1.6l2.2,2.2c0.4,0.4,1.1,0.4,1.6,0
	C21.8,13.6,21.8,12.9,21.3,12.5z"/>
<path d="M32.3,10.3c-0.4-0.4-1.1-0.4-1.6,0l-2.2,2.2c-0.4,0.4-0.4,1.1,0,1.6s1.1,0.4,1.6,0l2.2-2.2
	C32.7,11.4,32.7,10.7,32.3,10.3z"/>
<path d="M40.4,39.1c-0.1-0.6-0.7-0.9-1.3-0.8l-3.2,0.8l-1.6-6.5c4.3-1.7,5.8-6.7,3.7-10.7l-3.5-6.6
	c-0.2-0.4-0.7-0.7-1.2-0.6l-8.3,2.1l-8.3-2.1c-0.5-0.1-1,0.1-1.2,0.6l-3.5,6.6c-2.1,3.9-0.6,8.9,3.7,10.7L14,39.1l-3.2-0.8
	c-0.6-0.1-1.2,0.2-1.3,0.8c-0.1,0.6,0.2,1.2,0.8,1.3l8.5,2.1c0.6,0.1,1.2-0.2,1.3-0.8c0.1-0.6-0.2-1.2-0.8-1.3l-3.2-0.8l1.6-6.5
	c2.9,0.3,5.7-1.1,7.2-3.6c1.5,2.5,4.2,3.9,7.2,3.6l1.6,6.5l-3.2,0.8c-0.6,0.1-0.9,0.7-0.8,1.3c0.1,0.6,0.7,0.9,1.3,0.8l8.5-2.1
	C40.2,40.3,40.6,39.7,40.4,39.1z M17.2,30.8c-3.3-0.8-4.7-4.1-3.7-7.1l10.3,2.6C23.3,29.3,20.5,31.6,17.2,30.8z M23.8,24L23.8,24
	l-9.3-2.3l2.4-4.6l6.9,1.7V24z M26,24l0-5.2l6.9-1.7l2.4,4.6L26,24z M32.7,30.8c-3.3,0.8-6.1-1.4-6.6-4.5l10.3-2.6
	C37.4,26.6,36,30,32.7,30.8z"/>
<path d="M7.8,18.4l-0.3-1.9c-0.2-0.1-0.4-0.2-0.6-0.3s-0.4-0.3-0.6-0.4l-1.8,0.8l-1.4-2.5l1.7-1.2c0-0.1,0-0.2,0-0.3
	s0-0.2,0-0.3s0-0.2,0-0.3s0-0.2,0-0.3l-1.7-1.2L4.5,8l1.8,0.8c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.3l0.3-1.9h2.8l0.3,1.9
	c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4L13.9,8l1.4,2.5l-1.7,1.2c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3s0,0.2,0,0.3s0,0.2,0,0.3
	l1.7,1.2l-1.4,2.5l-1.8-0.8c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.3l-0.3,1.9C10.6,18.4,7.8,18.4,7.8,18.4z M9.2,14.3
	c0.6,0,1-0.2,1.4-0.6s0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4s-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4
	C8.2,14.1,8.6,14.3,9.2,14.3z M9.2,13.4c-0.3,0-0.5-0.1-0.8-0.3c-0.2-0.3-0.3-0.5-0.3-0.8s0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
	s0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.1,0.5-0.3,0.8C9.7,13.3,9.5,13.4,9.2,13.4z M9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3
	L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3L9.2,12.3z
	 M8.5,17.5h1.3l0.3-1.7c0.3-0.1,0.7-0.2,1-0.4c0.3-0.2,0.6-0.4,0.8-0.6l1.6,0.7l0.6-1.1l-1.4-1.1c0-0.2,0.1-0.3,0.1-0.5s0-0.3,0-0.5
	s0-0.3,0-0.5s-0.1-0.3-0.1-0.5l1.4-1.1l-0.6-1.1l-1.6,0.7c-0.2-0.3-0.5-0.5-0.8-0.7s-0.6-0.3-1-0.3L9.9,7.1H8.5L8.3,8.8
	C8,8.9,7.6,9,7.3,9.2S6.7,9.6,6.5,9.8L4.9,9.1l-0.6,1.1l1.4,1.1c0,0.2-0.1,0.3-0.1,0.5s0,0.3,0,0.5s0,0.3,0,0.5s0.1,0.3,0.1,0.5
	l-1.4,1.1l0.6,1.1l1.6-0.7C6.7,15,7,15.3,7.3,15.4c0.3,0.2,0.6,0.3,1,0.4L8.5,17.5z"/>
<path d="M16.9,9.6l-0.2-1.2c-0.1,0-0.3-0.1-0.4-0.2C16.2,8.1,16,8,15.9,8l-1.1,0.5l-0.9-1.6L15,6.1C15,6,15,6,15,5.9
	s0-0.1,0-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-1.1-0.8l0.9-1.6l1.1,0.5c0.1-0.1,0.2-0.2,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2l0.2-1.2h1.8
	L18.9,3c0.1,0,0.3,0.1,0.4,0.2s0.3,0.2,0.4,0.2l1.1-0.5l0.9,1.6l-1.1,0.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1,0,0.2l1.1,0.8l-0.9,1.6L19.7,8c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.2l-0.2,1.2
	C18.7,9.6,16.9,9.6,16.9,9.6z M17.8,6.9c0.4,0,0.6-0.1,0.9-0.4C19,6.3,19.1,6,19.1,5.7c0-0.4-0.1-0.6-0.4-0.9s-0.5-0.4-0.9-0.4
	s-0.6,0.1-0.9,0.4c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.4,0.1,0.6,0.4,0.9C17.2,6.8,17.5,6.9,17.8,6.9z M17.8,6.3c-0.2,0-0.3-0.1-0.5-0.2
	c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.3,0.2-0.5C17.4,5.1,17.6,5,17.8,5s0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5S18.4,6,18.3,6.2
	C18.2,6.3,18,6.3,17.8,6.3z M17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7
	L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7L17.8,5.7z M17.4,9h0.9l0.1-1.1c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.4
	l1,0.4L20.9,7L20,6.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3l0.9-0.7l-0.4-0.7l-1,0.4
	c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.2-0.4-0.2-0.6-0.3l-0.1-1.1h-0.9l-0.1,1.1c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.4
	l-1-0.4l-0.4,0.7L15.7,5c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3s0,0.2,0.1,0.3l-0.9,0.7l0.4,0.7l1-0.4
	c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2L17.4,9z"/>
</svg>
`;

export const RESERVATION_LANDING = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#20623B">
<path d="M13,42c-0.6,0-1.2-0.2-1.7-0.7c-0.5-0.5-0.7-1-0.7-1.7V18.8c0-0.6,0.2-1.2,0.7-1.7s1-0.7,1.7-0.7h11.2l2.4,2.4
	h13.5c0.6,0,1.2,0.2,1.7,0.7c0.5,0.5,0.7,1,0.7,1.7h-17l-2.4-2.4H13l0,0l0,0v20.8l0,0l0,0l4.1-16H45l-4.3,16.5
	c-0.2,0.6-0.5,1.1-0.9,1.4c-0.4,0.3-1,0.4-1.6,0.4H13z M15.6,39.6h22.8L41.8,26H18.9L15.6,39.6z M15.6,39.6L18.9,26L15.6,39.6z
	 M13,21.2v-2.4l0,0l0,0V21.2z"/>
<path d="M6.7,20.9L6.4,19c-0.2-0.1-0.4-0.2-0.6-0.3s-0.4-0.3-0.6-0.4l-1.8,0.8l-1.4-2.5l1.7-1.2c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3c0-0.1,0-0.2,0-0.3l-1.7-1.2l1.4-2.5l1.8,0.8c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.3
	l0.3-1.9h2.8l0.3,1.9c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4l1.8-0.8l1.4,2.5l-1.7,1.2c0,0.1,0,0.2,0,0.3
	c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0,0.1,0,0.2,0,0.3l1.7,1.2l-1.4,2.5L11,18.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.3
	l-0.3,1.9H6.7z M8.1,16.8c0.6,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4s-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6
	c-0.4,0.4-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4C7.1,16.6,7.5,16.8,8.1,16.8z M8.1,15.9c-0.3,0-0.5-0.1-0.8-0.3C7.1,15.3,7,15.1,7,14.8
	s0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.5,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.1,0.5-0.3,0.8
	C8.6,15.8,8.4,15.9,8.1,15.9z M8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8
	L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8L8.1,14.8z M7.4,20h1.3L9,18.3c0.3-0.1,0.7-0.2,1-0.4
	c0.3-0.2,0.6-0.4,0.8-0.6l1.6,0.7l0.6-1.1l-1.4-1.1c0-0.2,0.1-0.3,0.1-0.5c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5c0-0.2-0.1-0.3-0.1-0.5
	l1.4-1.1l-0.6-1.1l-1.6,0.7c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.3-1-0.3L8.8,9.6H7.4l-0.2,1.7c-0.3,0.1-0.7,0.2-1,0.4
	c-0.3,0.2-0.6,0.4-0.8,0.6l-1.6-0.7l-0.6,1.1l1.4,1.1c0,0.2-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0,0.5s0,0.3,0,0.5c0,0.2,0.1,0.3,0.1,0.5
	l-1.4,1.1L3.8,18l1.6-0.7c0.2,0.2,0.5,0.5,0.8,0.6c0.3,0.2,0.6,0.3,1,0.4L7.4,20z"/>
<path d="M15.8,12.1l-0.2-1.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.2l-1.1,0.5l-0.9-1.6l1.1-0.8
	c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2L12.8,7l0.9-1.6l1.1,0.5c0.1-0.1,0.2-0.2,0.4-0.2
	c0.1-0.1,0.3-0.1,0.4-0.2l0.2-1.2h1.8l0.2,1.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.2,0.4,0.2l1.1-0.5L20.6,7l-1.1,0.8
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l1.1,0.8l-0.9,1.6l-1.1-0.5c-0.1,0.1-0.2,0.2-0.4,0.2
	c-0.1,0.1-0.3,0.1-0.4,0.2l-0.2,1.2H15.8z M16.7,9.4c0.4,0,0.6-0.1,0.9-0.4C17.9,8.8,18,8.5,18,8.2c0-0.4-0.1-0.6-0.4-0.9
	s-0.5-0.4-0.9-0.4c-0.4,0-0.6,0.1-0.9,0.4c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.4,0.1,0.6,0.4,0.9C16.1,9.3,16.4,9.4,16.7,9.4z M16.7,8.8
	c-0.2,0-0.3-0.1-0.5-0.2C16.1,8.5,16,8.3,16,8.2c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2
	c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.5C17.1,8.8,16.9,8.8,16.7,8.8z M16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2
	L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2L16.7,8.2z M16.3,11.5h0.9
	l0.1-1.1c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.5-0.4l1,0.4l0.4-0.7l-0.9-0.7c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3l0.9-0.7l-0.4-0.7l-1,0.4c-0.1-0.2-0.3-0.3-0.5-0.4C17.7,6,17.5,6,17.3,5.9l-0.1-1.1h-0.9
	l-0.1,1.1c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.4l-1-0.4l-0.4,0.7l0.9,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3l-0.9,0.7l0.4,0.7l1-0.4c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.2,0.6,0.2L16.3,11.5z"/>
</svg>
`;

// AGENCY

export const NEW = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#20623B">
<path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
</svg>
`;

export const BUILD = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#20623B">
<path d="M22.61 18.99l-9.08-9.08c.93-2.34.45-5.1-1.44-7C9.79.61 6.21.4 3.66 2.26L7.5 6.11 6.08 7.52 2.25 3.69C.39 6.23.6 9.82 2.9 12.11c1.86 1.86 4.57 2.35 6.89 1.48l9.11 9.11c.39.39 1.02.39 1.41 0l2.3-2.3c.4-.38.4-1.01 0-1.41zm-3 1.6l-9.46-9.46c-.61.45-1.29.72-2 .82-1.36.2-2.79-.21-3.83-1.25C3.37 9.76 2.93 8.5 3 7.26l3.09 3.09 4.24-4.24-3.09-3.09c1.24-.07 2.49.37 3.44 1.31 1.08 1.08 1.49 2.57 1.24 3.96-.12.71-.42 1.37-.88 1.96l9.45 9.45-.88.89z"/>
</svg>
`;

// BANK

export const WATCH_LATER =`
<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="#20623B">
<path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z"/>
</svg>
`;

export const PENDING_ACTIONS = `
<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="#20623B">
<path d="M17,12c-2.76,0-5,2.24-5,5s2.24,5,5,5c2.76,0,5-2.24,5-5S19.76,12,17,12z M18.65,19.35l-2.15-2.15V14h1v2.79l1.85,1.85 L18.65,19.35z M18,3h-3.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H6C4.9,3,4,3.9,4,5v15c0,1.1,0.9,2,2,2h6.11 c-0.59-0.57-1.07-1.25-1.42-2H6V5h2v3h8V5h2v5.08c0.71,0.1,1.38,0.31,2,0.6V5C20,3.9,19.1,3,18,3z M12,5c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1C13,4.55,12.55,5,12,5z"/>
</svg>
`;

export const EVENT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#20623B">
<path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>
</svg>
`;

export const MAIL = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#20623B">
<path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
</svg>
`;

export const SERVICE = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#20623B">
<path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
</svg>
`;

// CONSULTANT

export const CONSULTANT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" fill="#20623B">
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
<path d="M1341 4829 l-31 -31 0 -144 0 -144 -384 0 c-254 0 -394 -4 -415 -11 -66 -23 -61 130 -61 -2039 0 -2166 -4 -2015 59 -2053 27 -15 80 -17 595 -17 l566 0 0 450 0 450 168 0 168 0 51 43 c29 23 52 45 52 50 1 4 -114 7 -254 7 l-255 0 0 95 0 95 394 0 393 0 109 51 c60 28 138 64 173 80 l63 29 -56 61
c-99 106 -165 227 -208 376 -19 66 -23 103 -23 238 0 176 14 247 75 381 170 375 590 585 976 488 l24 -6 -2 592 -3 592 -28 24 -28 24 -400 0 -399 0 0 141 0 141 -34 34 -34 34 -610 0 -610 0 -31 -31z m-301 -1214 l0 -255 -110 0 -110 0 0 255 0 255 110 0 110 0 0 -255z m280 0 l0 -255 -110 0 -110 0 0 255 0 255
110 0 110 0 0 -255z m655 0 l0 -250 -107 -3 -108 -3 0 256 0 256 108 -3 107 -3 0 -250z m285 0 l0 -256 -107 3 -108 3 -3 240 c-1 132 0 245 2 252 4 9 35 13 111 13 l105 0 0 -255z m660 5 l0 -250 -110 0 -110 0 0 250 0 250 110 0 110 0 0 -250z m289 0 l0 -250 -113 0 -113 0 0 250 -1 250 113 0 113 0 1 -250z
m-2169 -1045 l0 -255 -110 0 -110 0 0 255 0 255 110 0 110 0 0 -255z m280 0 l0 -255 -110 0 -110 0 0 255 0 255 110 0 110 0 0 -255z m655 0 l0 -250 -107 -3 -108 -3 0 256 0 256 108 -3 107 -3 0 -250z m285 0 l0 -256 -107 3 -108 3 -3 240 c-1 132 0 245 2 252 4 9 35 13 111 13 l105 0 0 -255z m-1222 -1124 l2
-251 -110 0 -110 0 0 256 0 256 108 -5 107 -5 3 -251z m282 4 l0 -255 -110 0 -110 0 0 248 c0 137 2 251 4 253 2 2 52 5 110 6 l106 2 0 -254z"/> 
<path d="M3146 3114 c-234 -57 -427 -251 -498 -501 -27 -94 -31 -276 -9 -362 37 -147 109 -269 216 -369 119 -112 252 -169 410 -179 286 -17 552 159 665 439 81 204 62 459 -47 648 -54 92 -181 216 -270 263 -139 73 -321 96 -467 61z"/> 
<path d="M3100 1535 c0 -3 23 -59 50 -125 l50 -120 -20 -257 c-11 -141 -22 -258 -23 -260 -2 -1 -99 162 -217 363 -118 201 -219 372 -225 380 -9 12 -25 8 -104 -26 -352 -153 -580 -385 -651 -664 -48 -188 -15 -239 233 -360 171 -83 399 -146 657 -183 181 -26 733 -26 905 0 274 41 475 98 660 187 202 97 245
140 245 245 0 106 -43 226 -126 351 -103 156 -273 291 -508 405 -103 50 -130 59 -137 48 -4 -8 -106 -179 -226 -381 -119 -202 -219 -366 -220 -364 -2 1 -12 117 -23 258 l-21 255 51 117 c27 65 50 122 50 127 0 5 -86 9 -200 9 -110 0-200 -2 -200 -5z m1040 -850 l0 -55 -200 0 -200 0 0 55 0 55 200 0 200 0 0 -55z"/>
</g>
</svg>
`;

// REPORT

export const REPORT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" fill="#20623B">
<path d="M15.4,16.9c0.4,0,0.7-0.1,1.1-0.5s0.5-0.6,0.5-1s-0.1-0.8-0.5-1.1s-0.7-0.4-1.1-0.4s-0.8,0.1-1.1,0.4
	s-0.4,0.7-0.4,1.1s0.1,0.7,0.4,1S15.1,16.9,15.4,16.9z M15.4,25.5c0.4,0,0.7-0.1,1.1-0.5c0.3-0.3,0.5-0.6,0.5-1s-0.1-0.8-0.5-1
	c-0.3-0.3-0.7-0.5-1.1-0.5s-0.8,0.1-1.1,0.5c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.8,0.4,1C14.7,25.3,15.1,25.5,15.4,25.5z M15.4,34
	c0.4,0,0.7-0.2,1.1-0.5s0.5-0.6,0.5-1s-0.1-0.7-0.5-1S15.8,31,15.4,31c-0.4,0-0.8,0.1-1.1,0.5s-0.4,0.6-0.4,1s0.1,0.8,0.4,1
	S15.1,34,15.4,34z M9,42c-0.8,0-1.5-0.3-2.1-0.9C6.3,40.5,6,39.8,6,39V9c0-0.8,0.3-1.5,0.9-2.1S8.2,6,9,6h23.1l9.9,9.9V39
	c0,0.8-0.3,1.5-0.9,2.1C40.5,41.7,39.8,42,39,42H9z M9,39h30l0,0l0,0V17.5h-8.5V9H9l0,0l0,0V39L9,39L9,39z M9,9v8.5V9v8.5V39l0,0
	l0,0l0,0l0,0V9L9,9L9,9z"/>
<path d="M21.9,35.4V23.2h6.6V26h6.9v9.4H21.9z M22.9,34.3h1.8v-1.8h-1.8V34.3z M22.9,31.6h1.8v-1.8h-1.8V31.6z
	 M22.9,28.8h1.8V27h-1.8V28.8z M22.9,26h1.8v-1.8h-1.8V26z M25.7,34.3h1.8v-1.8h-1.8V34.3z M25.7,31.6h1.8v-1.8h-1.8V31.6z
	 M25.7,28.8h1.8V27h-1.8V28.8z M25.7,26h1.8v-1.8h-1.8V26z M28.4,34.3h5.9V27h-5.9v1.8h1.4v1h-1.4v1.8h1.4v1h-1.4V34.3z M31.6,29.8
	v-1h1v1H31.6z M31.6,32.6v-1h1v1H31.6z"/>
</svg>
`;

export const REPORT_VIC = `
<svg xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" fill="#20623B">
<path d="M15.4,16.9c0.4,0,0.7-0.1,1.1-0.5s0.5-0.6,0.5-1s-0.1-0.8-0.5-1.1s-0.7-0.4-1.1-0.4s-0.8,0.1-1.1,0.4
	s-0.4,0.7-0.4,1.1s0.1,0.7,0.4,1S15.1,16.9,15.4,16.9z M15.4,25.5c0.4,0,0.7-0.1,1.1-0.5c0.3-0.3,0.5-0.6,0.5-1s-0.1-0.8-0.5-1
	c-0.3-0.3-0.7-0.5-1.1-0.5s-0.8,0.1-1.1,0.5c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.8,0.4,1C14.7,25.3,15.1,25.5,15.4,25.5z M15.4,34
	c0.4,0,0.7-0.2,1.1-0.5s0.5-0.6,0.5-1s-0.1-0.7-0.5-1S15.8,31,15.4,31c-0.4,0-0.8,0.1-1.1,0.5s-0.4,0.6-0.4,1s0.1,0.8,0.4,1
	S15.1,34,15.4,34z M9,42c-0.8,0-1.5-0.3-2.1-0.9C6.3,40.5,6,39.8,6,39V9c0-0.8,0.3-1.5,0.9-2.1S8.2,6,9,6h23.1l9.9,9.9V39
	c0,0.8-0.3,1.5-0.9,2.1C40.5,41.7,39.8,42,39,42H9z M9,39h30l0,0l0,0V17.5h-8.5V9H9l0,0l0,0V39L9,39L9,39z M9,9v8.5V9v8.5V39l0,0
	l0,0l0,0l0,0V9L9,9L9,9z"/>
<path d="M29.1,35.4v-0.9h5.1l0,0l0,0v-4.6c0-0.6-0.1-1.1-0.4-1.7s-0.6-1-1-1.4c-0.4-0.4-0.9-0.7-1.4-1
	c-0.5-0.2-1.1-0.4-1.7-0.4c-0.6,0-1.1,0.1-1.7,0.4c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.8,0.9-1,1.4c-0.3,0.5-0.4,1.1-0.4,1.7v3.7h-0.3
	c-0.3,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.4-0.5-0.4-0.9v-1.2c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.3,0.4-0.4l0-0.8c0.1-0.7,0.3-1.4,0.6-2
	c0.3-0.6,0.7-1.1,1.2-1.5c0.5-0.4,1-0.7,1.6-1c0.6-0.2,1.2-0.3,1.9-0.3c0.7,0,1.3,0.1,1.9,0.3c0.6,0.2,1.2,0.5,1.6,1
	c0.5,0.4,0.9,0.9,1.2,1.5c0.3,0.6,0.5,1.2,0.6,2l0,0.8c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.4,0.2,0.6v1.4c0,0.2-0.1,0.4-0.2,0.6
	c-0.1,0.2-0.3,0.3-0.4,0.4v1c0,0.3-0.1,0.5-0.3,0.6s-0.4,0.3-0.6,0.3H29.1z M27.9,31c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
	c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3
	C28.1,31,28,31,27.9,31z M31.5,31c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
	c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3C31.7,31,31.6,31,31.5,31z M26.1,30.2c0-0.6,0-1.1,0.2-1.6
	c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.3,0.7-0.6,1.2-0.8s0.9-0.3,1.4-0.3c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,1,1.3,1.1,2.2
	c-0.9,0-1.8-0.3-2.5-0.8c-0.7-0.5-1.3-1.1-1.7-1.9c-0.2,0.8-0.5,1.5-1,2.2C27.5,29.5,26.8,29.9,26.1,30.2z"/>
</svg>
`;

// RESERVATION

export const RESERVATION_REPORT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" fill="#20623B">
<path d="M15.4,16.9c0.4,0,0.7-0.1,1.1-0.5s0.5-0.6,0.5-1s-0.1-0.8-0.5-1.1s-0.7-0.4-1.1-0.4s-0.8,0.1-1.1,0.4
	s-0.4,0.7-0.4,1.1s0.1,0.7,0.4,1S15.1,16.9,15.4,16.9z M15.4,25.5c0.4,0,0.7-0.1,1.1-0.5c0.3-0.3,0.5-0.6,0.5-1s-0.1-0.8-0.5-1
	c-0.3-0.3-0.7-0.5-1.1-0.5s-0.8,0.1-1.1,0.5c-0.3,0.3-0.4,0.6-0.4,1s0.1,0.8,0.4,1C14.7,25.3,15.1,25.5,15.4,25.5z M15.4,34
	c0.4,0,0.7-0.2,1.1-0.5s0.5-0.6,0.5-1s-0.1-0.7-0.5-1S15.8,31,15.4,31c-0.4,0-0.8,0.1-1.1,0.5s-0.4,0.6-0.4,1s0.1,0.8,0.4,1
	S15.1,34,15.4,34z M9,42c-0.8,0-1.5-0.3-2.1-0.9C6.3,40.5,6,39.8,6,39V9c0-0.8,0.3-1.5,0.9-2.1S8.2,6,9,6h23.1l9.9,9.9V39
	c0,0.8-0.3,1.5-0.9,2.1C40.5,41.7,39.8,42,39,42H9z M9,39h30l0,0l0,0V17.5h-8.5V9H9l0,0l0,0V39L9,39L9,39z M9,9v8.5V9v8.5V39l0,0
	l0,0l0,0l0,0V9L9,9L9,9z"/>
</svg>
`;

// OTHER

