<div class="container">
    <div class="breadcrumb">
        <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
        <span class="breadcrumb-current">Gestione Prenotazioni</span>
    </div>

    <div class="menu-buttons">
        <div *ngIf="viewReservation" matRipple [matRippleCentered]="false" [matRippleDisabled]="false"
            [matRippleUnbounded]="false" class="menu-elements" (click)="goTo('report')">
            <div>
                <mat-icon svgIcon="reservation-report" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
            </div>
            <div style="margin-top: 15px">Estrazione Prenotazioni</div>
        </div>

        <div *ngIf="handleReservation" class="menu-elements" matRipple [matRippleCentered]="false"
            [matRippleDisabled]="false" [matRippleUnbounded]="false" (click)="goTo('handle')">
            <div>
                <mat-icon svgIcon="build" aria-hidden="false" style="height: 150px; width: 150px;"></mat-icon>
            </div>
            <div style="margin-top: 15px">Modifica Prenotazioni</div>
        </div>
    </div>
</div>