import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MyErrorHandler } from 'src/app/constants/error';
import { GenericConfirmationComponent } from 'src/app/modal/generic-confirmation/generic-confirmation.component';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { ModalSuccessComponent } from 'src/app/modal/modal-gestioneErrori/modal-success/modal-success.component';
import { CredemSettings } from 'src/app/model/settings.model';
import { ROUTES } from 'src/app/route/routes';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-settings-landing',
  templateUrl: './settings-landing.component.html',
  styleUrls: ['./settings-landing.component.scss']
})
export class SettingsLandingComponent implements OnInit {

  spinnerView: boolean = true;

  statusSelected: string = "INATTIVO";
  private status: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.settingsService.getSettings(["email_notification_active"]).subscribe(
      res => {
        if (res["email_notification_active"]["value"] == '1') {
          this.statusSelected = "ATTIVO";
          this.status = true;
        } else {
          this.statusSelected = "INATTIVO";
          this.status = false;
        }

        this.spinnerView = false;
      },
      err => {
        console.error(err);
        this.showError(err);
      }
    );
  }

  isSaveStatusDisabled(): boolean {
    return (this.status && this.statusSelected == "ATTIVO") || !(this.status || this.statusSelected == "ATTIVO");
  }

  saveStatus() {
    let action = this.statusSelected == "ATTIVO" ? "attivare" : "disattivare";
    let message = "Vuoi " + action + " la funzionalità di invio email?"
    this.openModal('email', message);
  }

  private openModal(value: string, message: string) {
    switch (value) {
      case 'email': {
        const dialogRef = this.dialog.open(GenericConfirmationComponent, {
          data: {
            message: message
          }
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.spinnerView = true;
            this.status = this.statusSelected == "ATTIVO";
            let value = this.status ? '1' : '0';
            let body = new CredemSettings("email_notification_active", value);
            this.settingsService.updateSettings([body]).subscribe(
              res => {
                this.showSuccess();
              },
              err => {
                this.showError(err);
              }
            );
          }
        });
      }
      break;

    }
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
    }
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('home');
    });
  }

  private showSuccess() {
    this.spinnerView = false;
    const dialogRef = this.dialog.open(ModalSuccessComponent);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

}
