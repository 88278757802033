import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from 'src/app/route/routes';

@Component({
  selector: 'app-event-landing',
  templateUrl: './event-landing.component.html',
  styleUrls: ['./event-landing.component.scss']
})
export class EventLandingComponent implements OnInit {

  spinnerView: boolean = false;

  constructor(private router: Router,) { }

  ngOnInit(): void {
    
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'new':
        this.router.navigate([ROUTES.EVENT_NEW.path]);
        break;
      case 'edit':
        this.router.navigate([ROUTES.EVENT_EDIT.path]);
        break;
    }
  }

}
