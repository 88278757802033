export const environment = {
  firebase: {
    apiKey: "AIzaSyD0rfBVSOUhcwULZFgSlZvXZZermQA8Fyk",
    authDomain: "goreply-credem-spazio-test.firebaseapp.com",
    projectId: "goreply-credem-spazio-test",
    storageBucket: "goreply-credem-spazio-test.appspot.com",
    messagingSenderId: "900203262299",
    appId: "1:900203262299:web:8f8e9eb4eee03653056a7e"
  },
  provider: "oidc.goreply-credem-spazio-test",
  production: true,
  isProd: false,
  backEndUrl: "https://be-admin-panel-dot-goreply-credem-spazio-test.oa.r.appspot.com",
  newEventUrl: "https://prenota-evento-collaudo.credem.it/home/",
  editUrl: "https://prenota-evento-collaudo.credem.it/edit/",
  mapsAPIKey: "AIzaSyD_hkfWTfB-OmzS-dtktyc50cUKCBWv7Sw"
};
