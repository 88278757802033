export class User {
    user_email!: string;
    user_roles!: ROLES[];
    is_new_OIDC: boolean | undefined = undefined;
}

export abstract class Role {

    static get_Role(myRole: string): ROLES | undefined {
        const match = Object.entries(ROLES).find(([key, value]) => myRole.indexOf(value) > -1);
        return match ? match[1] : undefined
    }
}

export enum ROLES {
    BANCA = "ADMIN_EVENTI.ACCESSO.BANCA",
    ADMIN = "ADMIN_EVENTI.ACCESSO.SUPERUSER",
    REPORT_READONLY = "ADMIN_EVENTI.ACCESSO.REPORT"
}