<div class="spinner-container" *ngIf="spinnerView">
    <div class="spinner-loading">
      <div>
        <span class="spinner-double-dot-stick"></span>
      </div>
    </div>
  </div>
  
  <div class="container" *ngIf="!spinnerView">
    <div class="breadcrumb">
      <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
      <span class="breadcrumb-current">Impostazioni Generali</span>
    </div>
  
    <div class="my-row">
      <mat-form-field class="my-form-field-color my-row-element-2">
          <mat-label>Status funzionalità di invio email:</mat-label>
          <mat-select
              [(ngModel)]="statusSelected" name="statusSelect">
              <mat-option *ngFor="let item of ['ATTIVO', 'INATTIVO']" [value]="item">{{item}}</mat-option>
          </mat-select>
      </mat-form-field>
    </div>

    <div class="my-button-container">
        <button class="my-button confirm" mat-raised-button (click)="saveStatus()"
            [disabled]="isSaveStatusDisabled()">
            SALVA
        </button>
    </div>
  </div>
  