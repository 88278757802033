import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private api_prefix: string;

  constructor(private http: HttpClient) {
    this.api_prefix = environment.backEndUrl;
  }

  getReservationsByEvent(uuid_event: string): Observable<any> {
    let url = this.api_prefix + `/reports/events/${uuid_event}`;

    return this.http.get(url);
  }

  getReservationsByEventWithUUID(uuid_event: string): Observable<any> {
    let url = this.api_prefix + `/reports/events/${uuid_event}`;

    let params: HttpParams = new HttpParams();
    params = params.set('with_uuid', true);

    return this.http.get(url, { params: params });
  }
}
