<div class="spinner-container" *ngIf="spinnerView">
  <div class="spinner-loading">
    <div>
      <span class="spinner-double-dot-stick"></span>
    </div>
  </div>
</div>

<div class="container" id="sectionTop" *ngIf="!spinnerView">
  <div class="breadcrumb" style="margin-top: 0px;">
    <span class="breadcrumb-previous" (click)="goTo('home')">Home</span> >
    <span class="breadcrumb-previous" (click)="goTo('landing')">Gestione Eventi</span> >
    <span class="breadcrumb-current">Modifica Evento</span>
  </div>

  <div class="my-row">

    <mat-form-field class="my-form-field-color my-row-element-3" appearance="fill">
      <mat-label>Seleziona l'evento di interesse</mat-label>
      <mat-select [(ngModel)]="eventSelected" name="typeSelect">
        <mat-option *ngFor="let item of allEvents" [value]="item">{{item.event_name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="my-row-element-2">
      <button class="my-button confirm" mat-raised-button [disabled]="isSearchDisabled()"
        (click)="search()">Cerca</button>
    </div>

  </div>

  <div *ngIf="eventModel?.uuid_event" style="margin-top: 30px;">
    <form name="myForm">

      <div class="my-row my-following-row">
        <div class="my-row-element-2">
          <label for="eventName">Nome Evento:</label>
          <br />
          <input class="my-input" type="text" id="eventName" name="eventName" #my_input_1="ngModel"
            [(ngModel)]="eventModel.event_name" autocomplete="off" maxlength="100" required disabled />

        </div>
        <div *ngIf="eventCategory.category_name" class="my-row-element-2">
          <label for="eventCategory">Categoria Evento:</label>
          <input class="my-input" type="text" id="eventCategory" name="eventCategory" #my_input_1="ngModel"
            [(ngModel)]="eventCategory.category_name" autocomplete="off" maxlength="100" required disabled />
        </div>
      </div>

      <div class="my-row following-row">
        <div class="my-row-element-1">

          <label for="eventuUrl">Link Diretto:</label>
          <br />
          <input class="my-input" type="text" id="eventUrl" name="eventUrl" #my_input_1="ngModel"
            [(ngModel)]="eventUrl" autocomplete="off" maxlength="255" required disabled/>

        </div>
      </div>


      <div class="my-row following-row">
        <div class="my-row-element-2">

          <label for="eventName">Indirizzo Evento:</label>
          <br />
          <input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" [options]="maps_options"
            class="my-input" type="text" id="eventAddress" name="eventAddress" [ngModel]="event_address_input"
            maxlength="100" required />

        </div>
      </div>

      <div class="my-row following-row">
        <label class="my-row-element-1" for="eventDescription">Descrizione Evento:</label>
      </div>

      <div class="my-row">
        <mat-form-field class="my-row-element-1" appearance="fill">
          <mat-label>Inserisci la descrizione</mat-label>
          <textarea type="text" id="eventDescription" name="eventDescription" #my_input_3="ngModel"
            [(ngModel)]="eventModel.event_description" autocomplete="off" maxlength="1000" matInput cdkTextareaAutosize
            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20">
                                              </textarea>
        </mat-form-field>
      </div>

      <div class="my-row following-row">
        <label for="eventDate">Data Evento:</label>
      </div>

      <div class="my-row">
        <mat-form-field class="my-row-element-3" appearance="fill">
          <mat-label>Seleziona una data</mat-label>
          <input matInput id="eventDate" name="eventDate" #my_input_4="ngModel" [matDatepicker]="picker" [min]="minDate"
            [(ngModel)]="eventModel.event_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <ng-container *ngIf="eventModel.event_date">
        <div class="my-row following-row">
          <label for="reservationEndDate">Data termine prenotazioni:</label>
        </div>

        <div class="my-row" style="justify-content: flex-start;">
          <mat-form-field class="my-row-element-3" appearance="fill" style="margin-right: 30px;">
            <mat-label>Seleziona una data</mat-label>
            <input matInput id="reservationEndDate" name="reservationEndDate" #my_input_5="ngModel"
              [matDatepicker]="picker2" [min]="minDate" [max]="eventModel.event_date"
              [(ngModel)]="eventModel.reservation_end_date">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="my-row-element-3" appearance="fill">
            <mat-label>Seleziona un orario</mat-label>
            <mat-select [(ngModel)]="reservation_end_time" name="reservation_end_time">
              <mat-option *ngFor="let el of end_times_list" [value]="el">
                {{el}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>

      <div class="my-row following-row">
        <label for="eventSlots">Orari Evento:</label>
      </div>

      <div class="my-row">
        <div class="my-row-element-3">

          <ng-container *ngIf="eventModel.event_slots?.length === 0; else slot_template">
            Nessuno slot esistente
          </ng-container>
          <ng-template #slot_template>
            <div *ngFor="let slot of eventModel.event_slots" class="slots">
              {{slot.start_time}} : {{slot.end_time}} - Capacità: {{slot.max_users}}
            </div>
          </ng-template>

        </div>
      </div>

      <div class="my-row following-row">
        <button class="my-button confirm" style="margin-left: 0px" mat-raised-button (click)="openSettingsSlots()">
          Modifica Orari
        </button>
      </div>

      <div class="my-row following-row">
        <div class="my-row-element-2">

          <label class="my-row-element-1" for="eventMaxReservation">Configurazioni Aggiuntive:</label>
          <br />
          <ng-container>
            <!--mat-checkbox [(ngModel)]="checked">Evento Privato</mat-checkbox-->
            <div style="display: flex; margin-top: 5px;">
              <div>
              <mat-checkbox [(ngModel)]="hasReservationLimit" id="hasReservationLimit" name="hasReservationLimit">
                Limite Prenotazioni per Persona
              </mat-checkbox>
              </div>
              <ng-container *ngIf="hasReservationLimit">
                <input style="width: 80px; margin: 0 0 0 30px;" class="my-input" type="number" id="eventMaxReservation"
                  name="eventMaxReservation" #my_input_6="ngModel"
                  [(ngModel)]="eventModel.event_max_reservations_per_user" autocomplete="off"
                  [min]="initialMinReservationLimit" max="100" required placeholder="Inserisci" />
                <div class="errorInputEvent">
                  <!-- TODO -->
                </div>
              </ng-container>
            </div>
            
            <div>
              <mat-checkbox [(ngModel)]="eventModel.event_is_private" id="isPrivate" name="isPrivate" disabled>
                Evento privato
              </mat-checkbox>
            </div>
              
            
          </ng-container>

        </div>
      </div>

      <br/>

      <div class="my-row-following-row">
        <div class="my-row-element-2">
          <label class="my-row-element-1" *ngIf="eventCategory.category_name">Campi richiesti per la categoria
            {{eventCategory.category_name}}:</label>
          <div *ngFor="let field of eventCategory.userFields | keyvalue">
            <p style="margin-bottom: -0.5em; margin-top:0.3em;">{{field.value.user_field_label}} </p>
            <!--<mat-checkbox [(ngModel)]="field.is_required" disabled></mat-checkbox> -->
          </div>
        </div>
      </div>
    </form>

    <div class="my-button-container">
      <button class="my-button cancel" mat-raised-button (click)="delete()">
        ELIMINA EVENTO
      </button>
      <button class="my-button confirm" mat-raised-button (click)="save()" [disabled]="isSaveDisabled()">
        CONFERMA MODIFICHE
      </button>
    </div>
  </div>