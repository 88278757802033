// Questi devono corrispondere, IN ORDINE, agli attributi della classe "ReportEvent"
export const xlsxColumns_event: string[][] = [[
  "Identificativo Prenotazione",
  "Nome Evento",
  "Data Appuntamento",
  "Data Prenotazione",
  "Cliente",
  "Email Cliente",
  "Cellulare Cliente",
  "Codice Fiscale",
  "Numero di partecipanti",
  "Numero di accompagnatori"
]];

// Questi devono corrispondere ai nomi di matColumnDef nella tabella
export const displayedColumns_event: string[] = [
  'data_evento',
  'cliente',
  'cliente_email',
  'cliente_cellulare',
  'numero_partecipanti',
  'identificativo_prenotazione'
];

export const displayedColumns_event_handling: string[] = [
  'data_evento',
  'cliente',
  'cliente_email',
  'cliente_cellulare',
  'numero_partecipanti',
  'identificativo_prenotazione',
  'edit'
];