import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { ReportEvent } from 'src/app/model/report.model';
import { ROUTES } from 'src/app/route/routes';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { MyErrorHandler } from 'src/app/constants/error';
import { MatDialog } from '@angular/material/dialog';

import * as XLSX from 'xlsx';
import { EventService } from 'src/app/services/event.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { CredemEvent } from 'src/app/model/credemEvent.model';
import { displayedColumns_event_handling, xlsxColumns_event } from 'src/app/constants/xls-columns';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reservation-handling',
  templateUrl: './reservation-handling.component.html',
  styleUrls: ['./reservation-handling.component.scss']
})

export class ReservationHandlingComponent implements OnInit, AfterViewInit {

  allEvents: Array<CredemEvent> = Array<CredemEvent>();
  eventSelected: CredemEvent | null = null;

  private allData_event: Array<ReportEvent> = [];
  private filteredArray: any =  [];
  private event_name_after_search: string | undefined;
  reservation_uuids: Array<string> = [];

  // Queste sono le colonne che verranno mostrate in tabella
  displayedColumns: string[] = [];
  // Attenzione, l'ordine deve rispettare quello dei campi nella classe Report
  private xlsxColumns: string[][] = [[]];
  
  dataSource = new MatTableDataSource();

  spinnerView: boolean = true;
  btnDownload: boolean = false;
  viewReservations: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private eventService: EventService,
    private reservationService: ReservationService
  ) { }

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void { 
    this.eventService.getAllEvents(true).subscribe(
      res => {
        this.allEvents = res;

        this.spinnerView = false;
      },
      err => {
        this.showError(err);
      }
    );
  }

  search() {
    if (!this.eventSelected || !this.eventSelected.uuid_event) return;

    this.spinnerView = true;
    this.displayedColumns = displayedColumns_event_handling;
    this.xlsxColumns = xlsxColumns_event;
    this.event_name_after_search = this.eventSelected.event_name;

    this.reservationService.getReservationsByEventWithUUID(this.eventSelected.uuid_event).subscribe(
      res => {
        this.allData_event = new Array<ReportEvent>();
        this.reservation_uuids = new Array<string>();

        res.data.forEach((el: any) => {
          let row = new ReportEvent(
            el.id_reservation_credem,
            el.event_name,
            el.slot_date_time,
            el.reservation_creation_date,
            el.user,
            el.user_email,
            el.user_phone,
            el.fiscal_code,
            el.number_of_users,
            el.number_of_guests
          );

          console.log(res);
          this.reservation_uuids.push(environment.editUrl + el.uuid_reservation + "?token=" + res.token);
          this.allData_event.push(row);
        });

        this.filteredArray = this.allData_event;

        this.setDatasource();
      },
      err => {
        this.showError(err);
      }
    );
  }

  private setDatasource() {
    this.dataSource = new MatTableDataSource(this.filteredArray);
    this.viewReservations = true;

    if (this.filteredArray.length == 0) {
      this.btnDownload = false;
    } else {
      this.btnDownload = true;
    }

    this.spinnerView = false;
  }

  isSearchDisabled() {
    return !this.eventSelected;
  }

  downloadFile(){
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(myworksheet, this.xlsxColumns);

    XLSX.utils.sheet_add_json(myworksheet, this.allData_event, { origin: 'A2', skipHeader: true });

    const myworkbook: XLSX.WorkBook = { Sheets: { 'Report': myworksheet }, SheetNames: ['Report'] };

    XLSX.writeFile(myworkbook, "Prenotazioni_" + this.event_name_after_search + ".xlsx");
  }

  private showError(err: any) {
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

  goTo(path: string) {
    switch(path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.RESERVATION.path]);
        break;
    }
  }
}
