import { DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { AngularMaterialModule, MyDateAdapter } from "./angular-material.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpConfigInterceptor } from "./interceptor/httpconfig.interceptor";
import { HomepageComponent } from "./landing/homepage/homepage.component";
import { MainPageComponent } from "./landing/main-page/main-page.component";
import { NotAllowedComponent } from "./landing/not-allowed/not-allowed.component";
import { PreLandingComponent } from "./landing/pre-landing/pre-landing.component";
import { ModalErrorComponent } from "./modal/modal-gestioneErrori/modal-error/modal-error.component";
import { ModalSuccessComponent } from "./modal/modal-gestioneErrori/modal-success/modal-success.component";
import { AuthGuard } from "./auth/auth.guard";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { GenericConfirmationComponent } from "./modal/generic-confirmation/generic-confirmation.component";
import { EventLandingComponent } from './component/event/event-landing/event-landing.component';
import { EventNewComponent } from './component/event/event-new/event-new.component';
import { EventSlotsModalComponent } from "./modal/modal-event/event-slots-modal/event-slots-modal.component";
import { ReservationReportComponent } from "./component/reservation/reservation-report/reservation-report.component";
import { ReservationLandingComponent } from './component/reservation/reservation-landing/reservation-landing.component';
import { ReservationHandlingComponent } from './component/reservation/reservation-handling/reservation-handling.component';
import { SettingsLandingComponent } from './component/settings/settings-landing/settings-landing.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { EventEditComponent } from './component/event/event-edit/event-edit.component';


@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    MainPageComponent,
    NotAllowedComponent,
    ModalSuccessComponent,
    ModalErrorComponent,
    GenericConfirmationComponent,
    PreLandingComponent,
    EventLandingComponent,
    EventNewComponent,
    EventSlotsModalComponent,
    ReservationReportComponent,
    ReservationLandingComponent,
    ReservationHandlingComponent,
    SettingsLandingComponent,
    EventEditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GooglePlaceModule,
    AngularMaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
  ],
  providers: [
    DatePipe,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'it'},
    { provide: DateAdapter, useClass: MyDateAdapter }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
