<div class="modalSuccess">
    <div>
      <h4 class="titleModalAgency">Operazione eseguita con successo</h4>
    </div>
    <ng-container *ngIf="message">
      {{ message }}
    </ng-container>
    <div class="my-button-container">
      <button class="my-button confirm" mat-raised-button (click)="close()">CHIUDI</button>
    </div>
</div>