import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { MyErrorHandler } from 'src/app/constants/error';
import { TIMESLOTS_SMALL } from 'src/app/constants/times';
import { GenericConfirmationComponent } from 'src/app/modal/generic-confirmation/generic-confirmation.component';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { ModalSuccessComponent } from 'src/app/modal/modal-gestioneErrori/modal-success/modal-success.component';
import { CredemEvent, EventSlot } from 'src/app/model/credemEvent.model';
import { ROUTES } from 'src/app/route/routes';
import { EventService } from 'src/app/services/event.service';
import { EventSlotsModalComponent } from '../../../modal/modal-event/event-slots-modal/event-slots-modal.component';
import { LIMITLESS_EVENT } from 'src/app/constants/others';
import { CategoryModel } from 'src/app/model/category.model';
import { UserFieldsService } from 'src/app/services/user_fields.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent implements OnInit {

  eventUrl: string | undefined;
  spinnerView: boolean = true;
  eventModel: CredemEvent = new CredemEvent();
  minDate = new Date();

  eventCategory: CategoryModel = new CategoryModel("", 1, "Categoria Generica");

  hasReservationLimit: boolean = false;
  initialMinReservationLimit: number = 1;

  allEvents: Array<CredemEvent> = Array<CredemEvent>();
  eventSelected: CredemEvent | null = null;

  event_address_input: string | undefined;
  reservation_end_time: string | undefined;
  end_times_list = TIMESLOTS_SMALL;

  maps_options: Options = new Options({
    fields: [
      "formatted_address",
      "geometry",
      "url",
    ],
    componentRestrictions: {
      country: "it"
    },
    strictBounds: true,
    types: []
  });

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private eventService: EventService,
    private userFieldService: UserFieldsService
  ) { }


  //Aggiungi Categoria evento read-only
  //Mostra se evento è privato (checkbox disabled)
  //Mostra gli userFields come in event_new_component

  //MODIFICA REPORT PER GESTIRE ESTRAZIONE CAMPI: AGGIUNGI CF DOPO NUM. TELEFONO, AGGIUNGI NUMERO DI ACCOMPAGNATORI

  ngOnInit(): void {
    this.eventService.getAllEvents(true).subscribe(
      res => {
        this.allEvents = res;
        this.spinnerView = false;
      },
      err => {
        this.showError(err);
      }
    );
  }

  handleAddressChange(address: any) {
    this.eventModel.event_address = address.formatted_address;
    this.eventModel.event_maps_link = address.url;
  }

  goTo(path: string) {
    switch (path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.EVENT.path]);
        break;
      case 'here':
        this.router.navigate([ROUTES.EVENT_EDIT.path]);
        break;
    }
  }

  isSearchDisabled() {
    return !this.eventSelected;
  }

  search() {
    if (!this.eventSelected || !this.eventSelected.uuid_event) return;
    this.spinnerView = true;
    let uuid = this.eventSelected.uuid_event;

    this.eventService.getEvent(uuid).subscribe(
      res => {

        this.event_address_input = res.event_address;

        this.eventModel.uuid_event = uuid;
        this.eventModel.event_name = res.event_name;
        this.eventModel.event_address = res.event_address;
        this.eventModel.event_maps_link = res.event_maps_link;
        this.eventModel.event_description = res.event_description;
        this.eventModel.event_date = new Date(res.event_date);
        this.eventModel.reservation_end_date = new Date(res.reservation_end_date);
        this.reservation_end_time = res.reservation_end_time;
        this.eventModel.event_max_reservations_per_user = res.event_max_reservations_per_user;
        this.eventModel.event_is_private = res.is_private;
        this.eventCategory.id_category = res.id_event_category;
        this.eventCategory.category_name = res.event_category_name;

        this.eventUrl = environment.newEventUrl + this.eventModel.uuid_event;
        

        this.userFieldService.getUserFields(this.eventCategory.id_category || 0).subscribe(fields => {
          if (fields) {
            this.eventCategory.userFields = fields;
          } else {
            console.error("Errore nel recupero degli user fields");
          }
        });

        if (!this.eventModel.event_max_reservations_per_user || this.eventModel.event_max_reservations_per_user == LIMITLESS_EVENT) {
          this.eventModel.event_max_reservations_per_user = undefined;
          this.hasReservationLimit = false;
        } else {
          this.hasReservationLimit = true;
          this.initialMinReservationLimit = JSON.parse(JSON.stringify(res.event_max_reservations_per_user));
        }

        let newSlots = new Array<EventSlot>();
        res.event_slots.forEach((el: any) => {
          newSlots.push(new EventSlot(el.start_time, el.end_time, el.max_users, el.id_event_slot));
        });
        this.eventModel.event_slots = newSlots;

        this.spinnerView = false;

      }, 
      err => {
        this.showError(err);
      }
    );
  }

  openSettingsSlots() {
    const dialogRef = this.dialog.open(EventSlotsModalComponent, {
      data: {
        inputSlots: this.eventModel.event_slots
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.eventModel.event_slots = result;
      }
    });
  }

  isSaveDisabled(): boolean {
    if ((this.eventModel.reservation_end_date instanceof Date)) {
      // Questo serve per poterlo confrontare con minDate sotto, sennò in caso di giorno uguale, il check falliva
      this.eventModel.reservation_end_date.setHours(23, 59, 59);
    }
    return !this.eventModel.event_name
      || !this.eventModel.event_address
      || !this.eventModel.event_maps_link
      || !this.eventModel.event_description
      || !this.eventModel.event_date
      || !this.eventModel.reservation_end_date
      || !this.reservation_end_time
      || !this.eventModel.event_slots || this.eventModel.event_slots.length == 0
      || this.eventModel.event_date < this.minDate
      || this.eventModel.reservation_end_date < this.minDate
      || this.eventModel.event_date < this.eventModel.reservation_end_date
      || (this.hasReservationLimit && !this.eventModel.event_max_reservations_per_user);
  }

  save() {
    if (
      !this.eventModel.event_date
      || !this.eventModel.reservation_end_date
      || !this.eventModel.uuid_event
    ) return;
    this.spinnerView = true;

    if ((this.eventModel.event_date instanceof Date)) {
      this.eventModel.event_date.setHours(12); // Sennò settava 00:00 e poi con la conversione a ISO portava al giorno precedente
      this.eventModel.event_date = this.eventModel.event_date.toISOString().substring(0, 10);
    }

    if ((this.eventModel.reservation_end_date instanceof Date)) {
      this.eventModel.reservation_end_date.setHours(12); // Sennò settava 00:00 e poi con la conversione a ISO portava al giorno precedente
      this.eventModel.reservation_end_date = this.eventModel.reservation_end_date.toISOString().substring(0, 10);
    }

    this.eventModel.reservation_end_date += " " + this.reservation_end_time;

    if (!this.hasReservationLimit) this.eventModel.event_max_reservations_per_user = LIMITLESS_EVENT;

    delete this.eventModel.event_name;
    let uuid = this.eventModel.uuid_event;
    delete this.eventModel.uuid_event;

    this.eventService.updateSingleEvent(this.eventModel, uuid).subscribe(
      data => {
        this.showSuccess();
      },
      err => {
        this.showError(err);
      }
    );
  }

  delete() {
    const dialogRef = this.dialog.open(GenericConfirmationComponent, {
      data: {
        message: "Vuoi confermare la cancellazione dell'evento? Tutti i partecipanti attuali riceveranno automaticamente una email di notifica."
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && this.eventModel?.uuid_event) {
        this.spinnerView = true;
        this.eventService.deleteSingleEvent(this.eventModel.uuid_event).subscribe(
          res => {
            this.showSuccess();
          },
          err => {
            this.showError(err);
          }
        );
      }
    });
  }

  private showSuccess() {
    this.spinnerView = false;
    const dialogRef = this.dialog.open(ModalSuccessComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

  private showError(err: any) {
    this.spinnerView = false;
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('here');
    });
  }

}
