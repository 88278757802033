import { ROLES } from "../model/user.model";

class Route {
    path: string = "";
    absolute_path: string = "";
    roles: ROLES[] = [];
}

export abstract class ROUTES {

    // HOME
    
    static readonly PRE_LANDING: Route = {
        path: "",
        absolute_path: "/",
        roles: []
    };

    static readonly NOT_ALLOWED: Route = {
        path: "not-allowed",
        absolute_path: "/not-allowed",
        roles: []
    };

    static readonly HOME: Route = {
        path: "home",
        absolute_path: "/home",
        roles: [ROLES.BANCA, ROLES.REPORT_READONLY, ROLES.ADMIN]
    };

    // EVENT

    static readonly EVENT: Route = {
        path: "eventi",
        absolute_path: "/eventi",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    static readonly EVENT_NEW: Route = {
        path: "eventi/nuovo",
        absolute_path: "/eventi/nuovo",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    static readonly EVENT_EDIT: Route = {
        path: "eventi/modifica",
        absolute_path: "/eventi/modifica",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    // RESERVATION

    static readonly RESERVATION: Route = {
        path: "prenotazioni",
        absolute_path: "/prenotazioni",
        roles: [ROLES.BANCA, ROLES.REPORT_READONLY, ROLES.ADMIN]
    }

    static readonly RESERVATION_REPORT: Route = {
        path: "prenotazioni/report",
        absolute_path: "/prenotazioni/report",
        roles: [ROLES.BANCA, ROLES.REPORT_READONLY, ROLES.ADMIN]
    }

    static readonly RESERVATION_HANDLE: Route = {
        path: "prenotazioni/gestisci",
        absolute_path: "/prenotazioni/gestisci",
        roles: [ROLES.BANCA, ROLES.ADMIN]
    }

    // SETTINGS

    static readonly SETTINGS: Route = {
        path: "settings",
        absolute_path: "/settings",
        roles: [ROLES.ADMIN]
    }

    // Add new Routes here

    static canAccess(roles: ROLES[], route: Route) {
        return route.roles.find(role => {return roles.includes(role)}) != undefined;
    }

    static getRoute(path: string) {
        return Object.entries(ROUTES).find(([key, value]) => {return value.absolute_path == path})
    }
}