import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '../../route/routes';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  @Input() viewEventi!: boolean;
  @Input() viewReservation!: boolean;
  @Input() viewSettings!: boolean;

  constructor(public router: Router) { }

  event_url = ROUTES.EVENT.path;
  reservation_url = ROUTES.RESERVATION.path;
  settings_url = ROUTES.SETTINGS.path;

  ngOnInit(): void {
  }

  goToUrl(url: string) {
    this.router.navigate([url]);
  }

}
