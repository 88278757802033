import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { MyErrorHandler } from 'src/app/constants/error';
import { TIMESLOTS_SMALL } from 'src/app/constants/times';
import { ModalErrorComponent } from 'src/app/modal/modal-gestioneErrori/modal-error/modal-error.component';
import { ModalSuccessComponent } from 'src/app/modal/modal-gestioneErrori/modal-success/modal-success.component';
import { CredemEvent } from 'src/app/model/credemEvent.model';
import { ROUTES } from 'src/app/route/routes';
import { EventService } from 'src/app/services/event.service';
import { UserFieldsService } from 'src/app/services/user_fields.service';
import { environment } from 'src/environments/environment';
import { EventSlotsModalComponent } from '../../../modal/modal-event/event-slots-modal/event-slots-modal.component';
import { LIMITLESS_EVENT } from 'src/app/constants/others';
import { CategoryModel } from 'src/app/model/category.model';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-event-new',
  templateUrl: './event-new.component.html',
  styleUrls: ['./event-new.component.scss']
})

export class EventNewComponent implements OnInit {
  categories: CategoryModel[] = [];
  spinnerView: boolean = true;
  eventModel: CredemEvent = new CredemEvent();

  minDate = new Date();
  //La categoria di default è "categoria generica"

  selectedCategory = new CategoryModel("", 1, "Categoria Generica");
  
  hasReservationLimit: boolean = false;
  isPrivate: boolean = false;

  reservation_end_time: string | undefined;
  end_times_list = TIMESLOTS_SMALL;

  userFields: any[] = [];

  maps_options: Options = new Options({
    fields: [
      "formatted_address",
      "geometry",
      "url",
    ],
    componentRestrictions: {
      country: "it"
    },
    strictBounds: true,
    types: []
  });

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private eventService: EventService,
    private userFieldService: UserFieldsService,
    private categoryService: CategoryService,
  ) { }

  ngOnInit(): void {
    this.categoryService.getCategories().subscribe((categoriesRes: CategoryModel[]) => {
      if(categoriesRes) {
        this.categories = categoriesRes;
        this.categories.forEach(category => {
          this.userFieldService.getUserFields(category.id_category || 0).subscribe(fields => {
            if(fields) {
              category.userFields = fields;
            }
          })
        });

        this.spinnerView = false;
      }
    },
    (error) => {
      console.error("Errore nel recupero delle categorie", error);
    }
    );
  } 

  handleAddressChange(address: any) {
    this.eventModel.event_address = address.formatted_address;
    this.eventModel.event_maps_link = address.url;
  }

  // Inizializza la data di scadenza al giorno precedente l'evento
  initDataTerminePrenotazioni(event: any) {
    if (event.value instanceof Date) {
      this.eventModel.reservation_end_date = new Date(event.value.valueOf());
      this.eventModel.reservation_end_date.setDate(this.eventModel.reservation_end_date.getDate() - 1);
      // Necessario per il confronto con minDate, altrimenti la data odierna non è valida
      this.eventModel.reservation_end_date.setHours(23);
      this.reservation_end_time = "12:00";
    }
  }

 updateSelectedCategory(cat_id: number) {
    if (this.categories){
      const matchingCategory = this.categories.find(category => category.id_category === cat_id);

      if (matchingCategory) {
        this.selectedCategory = matchingCategory;
        this.eventModel.event_fk_category = this.selectedCategory.id_category;
        this.eventModel.event_category_name = this.selectedCategory.category_name;
        this.eventModel.uuid_event_category = this.selectedCategory.uuid_category;

        if ( this.selectedCategory.userFields) {
          this.userFields = Object.values(this.selectedCategory.userFields);
        }
      }
      else {
        console.error("Categoria non trovata con ID:", cat_id);
      }
    }
  }

  goTo(path: string) {
    switch (path) {
      case 'home':
        this.router.navigate([ROUTES.HOME.path]);
        break;
      case 'landing':
        this.router.navigate([ROUTES.EVENT.path]);
        break;
      case 'here':
        this.router.navigate([ROUTES.EVENT_NEW.path]);
        break;
    }
  }

  openSettingsSlots() {
    const dialogRef = this.dialog.open(EventSlotsModalComponent, {
      data: {
        inputSlots: this.eventModel.event_slots
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.eventModel.event_slots = result;
      }
    });
  }


  isSaveDisabled(): boolean {
    return !this.eventModel.event_name
      || !this.eventModel.event_address
      || !this.eventModel.event_maps_link
      || !this.eventModel.event_description
      || !this.eventModel.event_date
      || !this.eventModel.reservation_end_date
      || !this.reservation_end_time
      || !this.eventModel.event_slots || this.eventModel.event_slots.length == 0
      || this.eventModel.event_date < this.minDate
      || this.eventModel.reservation_end_date < this.minDate
      || this.eventModel.event_date < this.eventModel.reservation_end_date
      || (this.hasReservationLimit && !this.eventModel.event_max_reservations_per_user)
      || !this.eventModel.event_category_name
      || !this.eventModel.event_fk_category
  }

  save() {
    if (!this.eventModel.event_date || !this.eventModel.reservation_end_date) return;
    this.spinnerView = true;
    if ((this.eventModel.event_date instanceof Date)) {
      this.eventModel.event_date.setHours(12); // Sennò settava 00:00 e poi con la conversione a ISO portava al giorno precedente
      this.eventModel.event_date = this.eventModel.event_date.toISOString().substring(0, 10);
    }

    if ((this.eventModel.reservation_end_date instanceof Date)) {
      this.eventModel.reservation_end_date.setHours(12); // Sennò settava 00:00 e poi con la conversione a ISO portava al giorno precedente
      this.eventModel.reservation_end_date = this.eventModel.reservation_end_date.toISOString().substring(0, 10);
    }

    this.eventModel.reservation_end_date += " " + this.reservation_end_time;

    if (!this.hasReservationLimit) this.eventModel.event_max_reservations_per_user = LIMITLESS_EVENT;

    //this.eventModel.id_event_category = this.selectedCategory;

    this.eventService.createSingleEvent(this.eventModel).subscribe(
      data => {
        console.log(data);
        let eventLink = environment.newEventUrl + data;
        let message = "Il link personalizzato per prenotare l'evento è:\n" + eventLink;
        this.showSuccess(message);
      },
      err => {
        this.showError(err);
      }
    );
  }

  private showSuccess(message?: string) {
    this.spinnerView = false;
    const dialogRef = this.dialog.open(ModalSuccessComponent, {
      data: message
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('landing');
    });
  }

  private showError(err: any) {
    this.spinnerView = false;
    const dialogRef = this.dialog.open(ModalErrorComponent, {
      data: MyErrorHandler.getText(err)
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.goTo('here');
    });
  }

}
