import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private api_prefix: string;

  constructor(private http: HttpClient) {
    this.api_prefix = environment.backEndUrl + "/settings";
  }

  getSettings(filters: Array<string>): Observable<any> {
    let url = this.api_prefix;

    let params: HttpParams = new HttpParams();
    let filter = "";
    filters.forEach(el => {
        filter += "," + el;
    });
    params = params.set("filter", filter);
    
    return this.http.get(url, { params: params });
  }

  updateSettings(body: any): Observable<any> {
    let url = this.api_prefix;
    return this.http.put(url, body);
  }
}
