import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '../../route/routes';

@Component({
  selector: 'app-pre-landing',
  templateUrl: './pre-landing.component.html',
  styleUrls: ['./pre-landing.component.scss']
})
export class PreLandingComponent implements OnInit {

  loading = false;

  constructor(
    private route: Router,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
  }

  goToHome(){
    this.ngZone.run(() => 
      this.route.navigate([ROUTES.HOME.path])
    )
  }

}
